import React from 'react';
import { FcLandscape } from 'react-icons/fc';
import { GiHouseKeys, GiGreenhouse } from 'react-icons/gi';

export default function Services() {
  return (
    <section>
      {/* new design service */}

      <div class="bg-[#f0fdf4] ">
        <div class="container px-6 py-10 mx-auto">
          <p className="text-center font-bold uppercase text-2xl mb-6 text-green-600">
            Our Services
          </p>
          <p className="text-center text-md text-gray-600 mb-6  ">
            Below is a number of the services we offer;
          </p>

          <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
            <div class="p-8 space-y-3 border-2 border-[#133042] rounded-xl">
              <span class="inline-block text-[#133042] ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                  />
                </svg>
              </span>

              <h1 class="text-xl font-semibold text-gray-700 capitalize ">
                Find your ideal next home or land across the 5 regions of
                Africa.
              </h1>

              <p class="text-gray-600 text-justify">
                For the 350M African Diaspora worldwide who would love to be
                able to invest in Real Estate back home and set themselves up
                for the future by buying land or a ready-made home, you now
                finally have a platform to give you full visibility of some of
                the best Real Estate across Africa. And for the 1.4 Billion
                citizens living across Africa, you also now have a place to call
                home when looking for a home to rent or buy in any African
                country.
              </p>
            </div>

            <div class="p-8 space-y-3 border-2 border-[#133042] rounded-xl">
              <span class="inline-block text-[#133042] ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                  />
                </svg>
              </span>

              <h1 class="text-xl font-semibold text-gray-700 capitalize ">
                Property & land listing (Advertise homes and land)
              </h1>

              <p class="text-gray-600 text-justify">
                If you’re a property developer, Estate Agent or a home owner,
                then Afri Find is offering you the chance to advertise your home
                or land to the millions of African Diaspora around the world, as
                well as over a billion citizens across the African continent.
              </p>
            </div>

            <div class="p-8 space-y-3 border-2 border-[#133042] rounded-xl">
              <span class="inline-block text[#133042] ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                  />
                </svg>
              </span>

              <h1 class="text-xl font-semibold text-gray-700 capitalize ">
                Generating leads for Estate Agencies and Realtors
              </h1>

              <p class="text-gray-600 text-justify">
                On a daily, weekly and monthly basis we also generate leads for
                both Established Estate Agents and 1 man band Realtors, who wish
                to either grow their business or get started as a start-up.
                Meaning you get emailed contact details of users who visit our
                platform or get in touch with our sales team looking for Real
                Estate within cities across Africa that our partner Estate
                Agents/Realtors specialise in.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
