import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import Spinner from '../components/Spinner';
import { BsShare, BsWhatsapp } from 'react-icons/bs';
import {
  FaMapMarkerAlt,
  FaBed,
  FaBath,
  FaParking,
  FaChair,
  FaShare,
} from 'react-icons/fa';
// swiper package import
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  EffectFade,
  Autoplay,
  Navigation,
  Pagination,
} from 'swiper';
import 'swiper/css/bundle'; // this css is importing from website
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import LandContact from '../components/LandContact';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from 'react-leaflet';
import { Icon } from 'leaflet';
import CustomerLandRegistration from '../components/CustomerLandRegistration';

export default function Land({ sellerNumber }) {
  const navigate = useNavigate();
  const auth = getAuth();
  const params = useParams();
  const [land, setLand] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  const [contactSeller, setContactSeller] = useState(false);
  const [position, setPosition] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);
  const [user, setUser] = useState(null);

  const authenticatedUser = auth.currentUser;

  SwiperCore.use([Autoplay, Navigation, Pagination]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  useEffect(() => {
    async function fetchLand() {
      const docRef = doc(db, 'lands', params.landId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setLand(docSnap.data());
        setLoading(false);
      }
    }
    fetchLand();
  }, [params.landId]);
  if (loading) {
    return <Spinner />;
  }

  // function location
  function LocationMarker() {
    const customIcon = new Icon({
      iconUrl: require('../assets/images/marker.png'),
      iconSize: [33, 33],
    });
    const map = useMapEvents({
      click(e) {
        // map.locate();
        console.log('----', e.latlng);
        console.log(e.latlng.lat);
        console.log(e.latlng.lng);
        setPosition(e.latlng);
      },
      // locationfound(e) {
      //   setPosition(e.latlng);
      //   map.flyTo(e.latlng, map.getZoom());
      // },
    });

    return position === null ? null : (
      <Marker position={position} icon={customIcon}>
        <Popup>{[land.lat, land.lng]}</Popup>
      </Marker>
    );
  }
  // const sellerNumber = land.sellerNumber;

  // const whatsappButton = document.getElementById('whatsappButton');
  const encodedMessage = encodeURIComponent(
    'Hello! I connect with AfriFind plaftrom, l see your '
  ); // Replace with your desired message

  // const whatsappLink = `https://api.whatsapp.com/send?phone=${sellerNumber}&text=${encodedMessage}${land.name}`;
  // whatsappButton = whatsappLink;

  return (
    <main>
      <Swiper
        slidesPerView={1}
        navigation
        pagination={{ type: 'progressbar' }}
        effect="fade"
        modules={[EffectFade]}
        autoplay={{ delay: 3000 }}
      >
        {land.imgUrls.map((url, index) => (
          <SwiperSlide key={index}>
            <div
              className="relative w-full overflow-hidden h-[300px]"
              style={{
                background: `url(${land.imgUrls[index]}) center no-repeat`,
                backgroundSize: 'cover',
              }}
            ></div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className="fixed top-[13%] right-[3%] z-10 bg-white cursor-pointer border-2 border-gray-400 rounded-full w-12 h-12 flex justify-center items-center"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          setShareLinkCopied(true);
          setTimeout(() => {
            setShareLinkCopied(false);
          }, 2000);
        }}
      >
        <FaShare className="text-lg text-slate-500" />
      </div>
      {shareLinkCopied && (
        <p className="fixed top-[23%] right-[5%] font-semibold border-2 border-gray-400 rounded-md bg-white z-10 p-2">
          Link Copied
        </p>
      )}
      {user && !isRegistered ? (
        <div className="m-4 flex flex-col md:flex-row max-w-6xl lg:mx-auto p-4 rounded-lg shadow-lg bg-white lg:space-x-5">
          <div className=" w-full ">
            <p className="text-2xl font-bold mb-3 text-[#133042]">
              {land.name}
              {/* {land.landId
              ? land.discountedPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : land.regularPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} */}
              {/* {land.type === 'rent' ? ' / month' : ''} */}
            </p>
            <p className="flex items-center mt-6 mb-3 font-semibold">
              <FaMapMarkerAlt className="text-green-700 mr-1" />
              {land.country}, {land.city}
            </p>
            {/* <p className="flex items-center mt-6 mb-3 font-semibold">
            <FaMapMarkerAlt className="text-green-700 mr-1" />
            {listing.country}
          </p>
          <p className="flex items-center mt-6 mb-3 font-semibold">
            <FaMapMarkerAlt className="text-green-700 mr-1" />
            {listing.city}
          </p> */}
            <div className="flex justify-start items-center space-x-4 w-[75%]">
              <p className="bg-red-800 w-full max-w-[200px] rounded-md p-1 text-white text-center font-semibold shadow-md">
                {'Land'}
              </p>
              {land.land && (
                <p className="w-full max-w-[200px] bg-green-800 rounded-md p-1 text-white text-center font-semibold shadow-md">
                  {/* ${+land.regularPrice - +land.discountedPrice} discount */}
                  Price: $
                  {land.regularPrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </p>
              )}
            </div>
            <p className="mt-3 mb-3">
              <span className="font-semibold">Description - </span>
              {land.discription}
            </p>
            <p className="mt-3 mb-3">
              <span className="font-semibold">Size - </span>
              {land.size} sqft
            </p>

            <p className="mt-3 mb-3">
              <span className="font-semibold">Contact person: </span>
              {land.sellerName}
            </p>
            <p className="mt-3 mb-3">
              {/* <span className="font-semibold">Seller WhastApp: </span> */}
              <a
                href="https://api.whatsapp.com/send?phone={sellerNumber}&text=Hello%20there!"
                id="whatsappLink"
                target="_blank"
                className="flex flex-row items-center justify-center w-full px-4 py-4 mb-4 text-lg text-white font-bold bg-green-600 leading-6 capitalize duration-100 transform rounded-sm shadow cursor-pointer
              focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 focus:outline-none sm:mb-0 sm:w-auto sm:mr-4 md:pl-8 md:pr-6 xl:pl-12 xl:pr-10 hover:shadow-lg hover:-translate-y-1"
              >
                <span className="ml-4">
                  <BsWhatsapp className="mr-4" />
                </span>
                Connect WhatsApp
              </a>
            </p>
            <ul className="flex items-center space-x-2 sm:space-x-10 text-sm font-semibold mb-6"></ul>
            {/* {land.userRef !== auth.currentUser?.uid && !contactSeller && (
            <div className="mt-6 ">
              <button
                onClick={() => setContactSeller(true)}
                className="px-7 py-3 bg-blue-600 rounded text-white font-medium text-sm uppercase shadow-md hover:bg-blue-700 hover:shadow-lg
          focus:bg-blue-700 focus:shadow-lg w-full text-center transition duration-150 ease-in-out "
              >
                Contact Seller Land
              </button>
            </div>
          )}
          {contactSeller && <LandContact userRef={land.userRef} land={land} />} */}
          </div>
          <div className="w-full h-[200px] md:h-[400px] z-10 overflow-x-hidden mt-6 md:mt-0 md:ml-2">
            {/* <MapContainer
            center={{ lat: 9.1021, lng: 18.2812 }}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: '100%', width: '100%' }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker />
          </MapContainer> */}
            <Swiper
              slidesPerView={1}
              navigation
              pagination={{ type: 'progressbar' }}
              effect="fade"
              modules={[EffectFade]}
              autoplay={{ delay: 3000 }}
            >
              {land.imgUrls.map((url, index) => (
                <SwiperSlide key={index}>
                  <div
                    className="relative w-full overflow-hidden h-[300px]"
                    style={{
                      background: `url(${land.imgUrls[index]}) center no-repeat`,
                      backgroundSize: 'cover',
                    }}
                  ></div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      ) : (
        <div className="">
          {/* <h2>Please register to see the view property details</h2> */}
          {isRegistered ? (
            <div className="m-4 flex flex-col md:flex-row max-w-6xl lg:mx-auto p-4 rounded-lg shadow-lg bg-white lg:space-x-5">
              <div className=" w-full ">
                <p className="text-2xl font-bold mb-3 text-[#133042]">
                  {land.name}
                  {/* {land.landId
                ? land.discountedPrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : land.regularPrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} */}
                  {/* {land.type === 'rent' ? ' / month' : ''} */}
                </p>
                <p className="flex items-center mt-6 mb-3 font-semibold">
                  <FaMapMarkerAlt className="text-green-700 mr-1" />
                  {land.country}, {land.city}
                </p>
                {/* <p className="flex items-center mt-6 mb-3 font-semibold">
              <FaMapMarkerAlt className="text-green-700 mr-1" />
              {listing.country}
            </p>
            <p className="flex items-center mt-6 mb-3 font-semibold">
              <FaMapMarkerAlt className="text-green-700 mr-1" />
              {listing.city}
            </p> */}
                <div className="flex justify-start items-center space-x-4 w-[75%]">
                  <p className="bg-red-800 w-full max-w-[200px] rounded-md p-1 text-white text-center font-semibold shadow-md">
                    {'Land'}
                  </p>
                  {land.land && (
                    <p className="w-full max-w-[200px] bg-green-800 rounded-md p-1 text-white text-center font-semibold shadow-md">
                      {/* ${+land.regularPrice - +land.discountedPrice} discount */}
                      Price: $
                      {land.regularPrice
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </p>
                  )}
                </div>
                <p className="mt-3 mb-3">
                  <span className="font-semibold">Description - </span>
                  {land.discription}
                </p>
                <p className="mt-3 mb-3">
                  <span className="font-semibold">Size - </span>
                  {land.size} sqft
                </p>

                <p className="mt-3 mb-3">
                  <span className="font-semibold">Contact person: </span>
                  {land.sellerName}
                </p>
                <p className="mt-3 mb-3">
                  {/* <span className="font-semibold">Seller WhastApp: </span> */}
                  <a
                    href="https://api.whatsapp.com/send?phone={sellerNumber}&text=Hello%20there!"
                    id="whatsappLink"
                    target="_blank"
                    className="flex flex-row items-center justify-center w-full px-4 py-4 mb-4 text-lg text-white font-bold bg-green-600 leading-6 capitalize duration-100 transform rounded-sm shadow cursor-pointer
                focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 focus:outline-none sm:mb-0 sm:w-auto sm:mr-4 md:pl-8 md:pr-6 xl:pl-12 xl:pr-10 hover:shadow-lg hover:-translate-y-1"
                  >
                    <span className="ml-4">
                      <BsWhatsapp className="mr-4" />
                    </span>
                    Connect WhatsApp
                  </a>
                </p>
                <ul className="flex items-center space-x-2 sm:space-x-10 text-sm font-semibold mb-6"></ul>
                {/* {land.userRef !== auth.currentUser?.uid && !contactSeller && (
              <div className="mt-6 ">
                <button
                  onClick={() => setContactSeller(true)}
                  className="px-7 py-3 bg-blue-600 rounded text-white font-medium text-sm uppercase shadow-md hover:bg-blue-700 hover:shadow-lg
            focus:bg-blue-700 focus:shadow-lg w-full text-center transition duration-150 ease-in-out "
                >
                  Contact Seller Land
                </button>
              </div>
            )}
            {contactSeller && <LandContact userRef={land.userRef} land={land} />} */}
              </div>
              <div className="w-full h-[200px] md:h-[400px] z-10 overflow-x-hidden mt-6 md:mt-0 md:ml-2">
                {/* <MapContainer
              center={{ lat: 9.1021, lng: 18.2812 }}
              zoom={13}
              scrollWheelZoom={false}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker />
            </MapContainer> */}
                <Swiper
                  slidesPerView={1}
                  navigation
                  pagination={{ type: 'progressbar' }}
                  effect="fade"
                  modules={[EffectFade]}
                  autoplay={{ delay: 3000 }}
                >
                  {land.imgUrls.map((url, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="relative w-full overflow-hidden h-[300px]"
                        style={{
                          background: `url(${land.imgUrls[index]}) center no-repeat`,
                          backgroundSize: 'cover',
                        }}
                      ></div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ) : (
            <CustomerLandRegistration
              setIsRegistered={setIsRegistered}
              landId={params.landId}
              isRegistered={isRegistered}
            />
          )}
        </div>
      )}
    </main>
  );
}
