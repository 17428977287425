import React from 'react';
import spinner from '../assets/images/afrifind-white.png';

export default function Spinner() {
  return (
    <div
      className="bg-black bg-opacity-50 flex items-center justify-center fixed 
    left-0 right-0 bottom-0 top-0 z-50"
    >
      <div>
        <img
          src={spinner}
          alt="loading..."
          className="h-16 w-34 p-1 justify-center items-center animate-bounce bg-[#133042] rounded-sm"
        />
        <p className="h-16 justify-center items-center text-white text-xl text-center">
          Please wait...
        </p>
      </div>
    </div>
  );
}
