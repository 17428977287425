import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

function CustomerLandRegistration({ setIsRegistered, landId }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    type: 'Land For Sale',
    email: '',
    country: '',
    city: '',
    address: '',
    agreeConfirm: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // send registration data to firebase firestore
      const customersRef = collection(db, 'customers');
      const newCustomerRef = await addDoc(customersRef, {
        ...formData,
        timestamp: serverTimestamp(),
      });

      // success message
      alert('Succesfull Registration');

      // redirect to the land detail page after success
      navigate(`/land/${landId}`);
      setIsRegistered(true);
    } catch (error) {
      console.error('Error registering as a customer:', error);
      alert('Error registering as a customer. Please try again later.');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="z-10 p-6 bg-white w-full max-w-md mx-auto rounded-lg shadow-lg">
        <h2 className="text-center text-xl mb-2">Customer Form Register</h2>
        <div className="flex justify-between  mb-4">
          <h2 className="text-md  text-gray-500">
            Please Register to see detial page
          </h2>
          <span className="bg-green-500 text-white rounded-s-full px-2 py-1 text-lg font-bold cursor-pointer hover:shadow-md hover:text-[#133042]">
            <a href="/">X</a>
          </span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              id="name"
              placeholder="Enter Your FullName"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-gray-300"
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              id="phone"
              placeholder="Enter Your Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-green-400"
            />
          </div>
          <div className="mb-4">
            {/* <label
            htmlFor="propertyOrLand"
            className="block text-sm font-normal"
          >
            Select type you want
          </label> */}
            <select
              id="type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#13304267]"
            >
              <option value="Land">Land For Sale</option>
            </select>
          </div>
          <div className="mb-4">
            {/* <label htmlFor="country">Country</label> */}
            <input
              type="text"
              id="country"
              name="country"
              placeholder="Enter Country you want"
              value={formData.country}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#133042]"
            />
          </div>
          <div className="mb-4">
            {/* <label htmlFor="addressOrCity">Enter full address </label> */}
            <input
              type="text"
              id="city"
              name="city"
              placeholder="Enter City "
              value={formData.city}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#133042]"
            />
          </div>
          <div className="mb-4">
            {/* <label htmlFor="addressOrCity">Enter full address </label> */}
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Enter Address you want "
              value={formData.address}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#133042]"
            />
          </div>
          <div className="mb-4">
            <label>
              <input
                type="checkbox"
                name="agreeConfirm"
                checked={formData.agreeConfirm}
                onChange={handleChange}
                className=" mr-3 px-2 py-2 "
              />
              <span className="text-sm">
                I agree to the terms and conditions of AfriFind.
              </span>
            </label>
          </div>
          <div className="mt-6">
            <button
              type="submit"
              className="w-full px-4 py-2 bg-green-700 text-white rounded-md hover:bg-[#133042] focus:outline-none focus:ring focus:ring-green-200"
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CustomerLandRegistration;
