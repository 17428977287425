import React from 'react';
import { useParams } from 'react-router-dom';
import somalia from '../assets/images/somalia.jpg';
import blogpost from '../assets/images/blogpost.jpg';
import africa from '../assets/images/animalafrica.jpeg';
import {
  BsFacebook,
  BsTwitter,
  BsInstagram,
  BsLinkedin,
  BsYoutube,
} from 'react-icons/bs';

const BlogDetailPage = () => {
  // Use useParams to read the 'id' parameter from the URL
  const { id } = useParams();

  // Assuming you have the 'blogsData' array here, containing the blog data
  const blogsData = [
    {
      id: 1,
      title:
        'The lack of digitalisation hampering Africa’s $143 billion dollar Real Estate sector, is there a solution?',
      content: `Could you imagine being part of the African Diaspora, sending back $53 billion per year to support your loved ones and invest in land or buy a home in your home country? However, you find yourself stuck due to the lack of digitalisation, with no online platform to explore the latest available Real Estate options.
      Could you also imagine being one of the 1.4 billion citizens living on the African continent who are also facing similar challenges, relying on word of mouth, Facebook ads, or the help of friends and family to find suitable homes or land?
      Or the Property Developers, Estate Agents, and homeowners who are fed up with fraudulent Realtors jeopardising the sector by selling buyers' land that is already owned by someone else due to the absence of digital buyer traceability.
      Well, these have been some of the challenges hampering Africa’s $143 billion dollar Real Estate sector due to only 7% of the sector’s trade happening online.
      Recognising these frustrations, Afri Find has emerged as a pioneering Property Technology platform designed to address the lack of 
      digitalisation in the African Real Estate sector. Afri Find offers a comprehensive one-stop-shop solution to connect individuals from home and abroad 
      with vetted properties and land across Africa, leveraging the power of technology to revolutionise the Real Estate industry.`,
      image: somalia,
    },
    // {
    //   id: 2,
    //   title: 'Blog Two Title',
    // },
  ];

  // Find the blog data with the matching 'id' from the URL
  const blog = blogsData.find((blog) => blog.id === parseInt(id));

  // Check if the blog exists
  if (!blog) {
    return <div>Blog not found.</div>;
  }

  // Extract the blog data
  const { title, content, image } = blog;

  return (
    <>
      {/* <div className="container px-6 py-10 mx-auto">
        <div className="text-left">
          <h1 className="text-2xl font-semibold text-gray-800  dark:text-white">
            {title}
          </h1>
        </div>
        <div className="mt-8 prose dark:prose-dark">
          <div className="flex">
            <p className="container">{content}</p>
            <img
              className="relative z-10 object-cover w-full rounded-md h-96"
              src={image}
            />
          </div>
        </div>
      </div> */}

      <section>
        <div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8  ">
          <div className="grid grid-cols-1  lg:grid-cols-1">
            <div className="relative z-10 lg:py-16">
              <div className=" h-54 sm:h-50 lg:h-64">
                <img
                  alt="House"
                  src="https://images.unsplash.com/photo-1583608205776-bfd35f0d9f83?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                  className="absolute inset-0 h-full w-full object-cover"
                />
              </div>
            </div>

            <div className="relative flex items-center bg-gray-100">
              <span className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-gray-100"></span>

              <div className="p-8 sm:p-16 lg:p-24">
                <h2 className="text-2xl font-bold sm:text-3xl">
                  The lack of digitalisation hampering Africa’s $143 billion
                  dollar Real Estate sector, is there a solution?
                </h2>

                <p className="mt-4 text-gray-800">
                  Could you imagine being part of the African Diaspora, sending
                  back $53 billion per year to support your loved ones and
                  invest in land or buy a home in your home country? However,
                  you find yourself stuck due to the lack of digitalisation,
                  with no online platform to explore the latest available Real
                  Estate options. <br />
                  <br />
                  Could you also imagine being one of the 1.4 billion citizens
                  living on the African continent who are also facing similar
                  challenges, relying on word of mouth, Facebook ads, or the
                  help of friends and family to find suitable homes or land?
                  <br />
                  <br />
                  Or the Property Developers, Estate Agents, and homeowners who
                  are fed up with fraudulent Realtors jeopardising the sector by
                  selling buyers' land that is already owned by someone else due
                  to the absence of digital buyer traceability.
                  <br />
                  <br />
                  Well, these have been some of the challenges hampering
                  Africa’s $143 billion dollar Real Estate sector due to only 7%
                  of the sector’s trade happening online.
                  <br />
                  <br />
                  Recognising these frustrations, Afri Find has emerged as a
                  pioneering Property Technology platform designed to address
                  the lack of digitalisation in the African Real Estate sector.
                  Afri Find offers a comprehensive one-stop-shop solution to
                  connect individuals from home and abroad with vetted
                  properties and land across Africa, leveraging the power of
                  technology to revolutionise the Real Estate industry.
                  <br />
                  <br />
                </p>
                <img src={africa} alt="" className="w-29 rounded-md" />
                <h4>
                  Let's delve into why Afri Find is the ultimate solution for
                  these pressing challenges.
                </h4>
                <p className="mt-4 text-gray-800">
                  <span className="font-bold">
                    Empowering the African Diaspora;
                  </span>
                  <br />
                  Afri Find understands the aspirations of the African Diaspora,
                  enabling them to transcend the barriers imposed by the lack of
                  digitalisation. By providing an intuitive online platform,
                  Afri Find empowers the diaspora to browse and access the
                  latest available Real Estate opportunities in their chosen
                  African nation. This ensures that the $53 billion sent back
                  annually can be invested with confidence in vetted lands and
                  properties through Afri Find, a platform tailored specifically
                  to their needs. <br />
                  <br />
                  <span className="font-bold">
                    Enabling Seamless Real Estate Transactions;
                  </span>
                  <br />
                  Afri Find serves as a game-changer for the 1.4 billion
                  citizens residing in Africa, eradicating the dependence on
                  unreliable methods such as word of mouth and Facebook
                  advertisements. With Afri Find, individuals can easily find
                  homes to rent or land to purchase, streamlining the entire
                  process and eliminating unnecessary time wastage. By
                  centralising the Real Estate market onto a digital platform,
                  Afri Find facilitates efficient and trustworthy transactions
                  for all users. <br />
                  <br />
                  <img src={blogpost} alt="" className="w-[500px] rounded-md" />
                  <span className="font-bold">
                    Enhancing Trust and Security
                  </span>
                  <br />
                  Fraudulent realtors have long plagued the African Real Estate
                  sector, causing considerable damage to both individuals and
                  businesses. Afri Find tackles this issue head-on by
                  implementing robust buyer traceability mechanisms. By creating
                  a digital footprint for buyers and sellers, Afri Find ensures
                  that multiple sales of the same plot of land are eliminated,
                  instilling trust and transparency in the market. This vital
                  feature safeguards the interests of Property Developers,
                  Estate Agents, and homeowners as well as potential buyers,
                  helping to restore confidence in the sector. <br />
                  <br />
                  <span className="font-bold">
                    Driving Sustainable and ESG-friendly Development;
                  </span>
                  <br />
                  Afri Find's impact extends beyond individual transactions. The
                  platform also serves as a catalyst for sustainable,
                  environmentally conscious Real Estate development. Empowering
                  Property Developers to build and showcase their ESG-friendly
                  projects with Afri Find, as this promotes responsible
                  practices in the industry. This not only contributes to the
                  long-term well-being of the environment but also positions
                  African Property Developers as developers who care about
                  sustainable Real Estate. <br />
                  <br />
                  <span className="font-bold">
                    Supporting Estate Agents and Homeowners;
                  </span>
                  <br />
                  Estate Agents play a crucial role in connecting buyers and
                  sellers, and Afri Find recognises their significance. The
                  platform equips Estate Agents with licenses that allow them to
                  efficiently filter and search for buyers and renters in their
                  preferred locations. Additionally, Afri Find provides
                  homeowners with a unique opportunity to rent or sell their
                  properties in Africa through a secure and transparent
                  platform, expanding their reach and ensuring hassle-free
                  transactions. <br />
                  <br />
                </p>
                <p>
                  We’re passionate about bringing meaningful solutions to the
                  African Real Estate sector, so if you wish to hear more from
                  us as we update you on a daily and weekly basis in relation to
                  all things African Real Estate, then please either subscribe
                  to our newsletter or follow us across our Social media
                  channels;
                </p>
                <div className="flex items-center mt-3 space-x-3">
                  <a
                    href="https://www.facebook.com/Afrifind"
                    className="text-[#133042] transition-colors duration-300 hover:text-[#68b71c]"
                  >
                    <BsFacebook className="h-5 w-5 text-[#133042]" />
                  </a>
                  <a
                    href="https://twitter.com/AfriFind1"
                    className="text-[#133042] transition-colors duration-300 hover:text-[#68b71c]"
                  >
                    <BsTwitter className="h-5 w-5 text-[#133042]" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/afri-find/"
                    className="text-[#133042] transition-colors duration-300 hover:text-[#68b71c]"
                  >
                    <BsLinkedin className="h-5 w-5 text-[#133042]" />
                  </a>
                  <a
                    href="https://www.instagram.com/afri.find/"
                    className="text-[#133042] transition-colors duration-300 hover:text-[#68b71c]"
                  >
                    <BsInstagram className="h-5 w-5 text-[#133042]" />
                  </a>
                  <a
                    href="https://www.youtube.com/@afrifind"
                    className="text-[#133042] transition-colors duration-300 hover:text-[#68b71c]"
                  >
                    <BsYoutube className="h-5 w-5 text-[#133042]" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetailPage;
