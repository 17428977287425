import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  collection,
  getDocs,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { db } from '../firebase';
import Spinner from '../components/Spinner';
import ListingItem from '../components/ListingItem';
import { async } from '@firebase/util';

export default function Properties() {
  const [listings, setListings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastFetchedListing, setLastFetchedListing] = useState(null);
  const [availableCountreis, setAvailableCountries] = useState([]);
  const [filters, setFilters] = useState({
    region: '',
    country: '',
    status: '',
    search: '',
  });
  const [filteredListings, setFilteredListings] = useState(null);

  // handle filter change
  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
    applyFilters(value); // Call the applyFilters function with the updated value
  };

  // Fetch listings and countries
  useEffect(() => {
    fetchListings();
    fetchCountries();
  }, []);

  useEffect(() => {
    setFilteredListings(listings);
  }, [listings]);

  // Fetch listings
  async function fetchListings() {
    try {
      const listingRef = collection(db, 'listings');
      const q = query(
        listingRef,
        where('property', '==', true),
        orderBy('timestamp', 'desc')
      );

      const querySnap = await getDocs(q);
      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchedListing(lastVisible);
      const fetchedListings = [];
      querySnap.forEach((doc) => {
        fetchedListings.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setListings(fetchedListings);
      setLoading(false);
    } catch (error) {
      toast.error('Could not fetch property');
    }
  }

  // Fetch countries
  async function fetchCountries() {
    try {
      const listingRef = collection(db, 'listings');
      const q = query(listingRef);
      const querySnap = await getDocs(q);
      const countries = new Set();
      querySnap.forEach((doc) => {
        const country = doc.data().country;
        if (country) {
          countries.add(country);
        }
      });
      setAvailableCountries(Array.from(countries));
    } catch (error) {
      toast.error('Could not fetch countries');
    }
  }

  // Fetch more listings
  async function onFetchMoreListings() {
    try {
      const listingRef = collection(db, 'listings');
      const q = query(
        listingRef,
        where('property', '==', true),
        orderBy('timestamp', 'desc'),
        startAfter(lastFetchedListing)
      );

      const querySnap = await getDocs(q);
      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchedListing(lastVisible);
      const fetchedListings = [];
      querySnap.forEach((doc) => {
        fetchedListings.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setFilteredListings((prevState) => [...prevState, ...fetchedListings]);
      setLoading(false);
    } catch (error) {
      toast.error('Could not fetch listing');
    }
  }

  // Apply filters function
  const applyFilters = () => {
    let newFiltered;

    if (filters.region && filters.country && filters.status && filters.search) {
      newFiltered = listings.filter(
        (listing) =>
          listing.data.region === filters.region &&
          listing.data.country === filters.country &&
          listing.data.type === filters.status &&
          listing.data.city.toLowerCase().includes(filters.search.toLowerCase())
      );
    } else if (filters.region && filters.country && filters.status) {
      newFiltered = listings.filter(
        (listing) =>
          listing.data.region === filters.region &&
          listing.data.country === filters.country &&
          listing.data.type === filters.status
      );
    } else if (filters.region && filters.country && filters.search) {
      newFiltered = listings.filter(
        (listing) =>
          listing.data.region === filters.region &&
          listing.data.country === filters.country &&
          listing.data.city.toLowerCase().includes(filters.search.toLowerCase())
      );
    } else if (filters.region && filters.status && filters.search) {
      newFiltered = listings.filter(
        (listing) =>
          listing.data.region === filters.region &&
          listing.data.type === filters.status &&
          listing.data.city.toLowerCase().includes(filters.search.toLowerCase())
      );
    } else if (filters.country && filters.status && filters.search) {
      newFiltered = listings.filter(
        (listing) =>
          listing.data.country === filters.country &&
          listing.data.type === filters.status &&
          listing.data.city.toLowerCase().includes(filters.search.toLowerCase())
      );
    } else if (filters.region && filters.country) {
      newFiltered = listings.filter(
        (listing) =>
          listing.data.region === filters.region &&
          listing.data.country === filters.country
      );
    } else if (filters.region && filters.status) {
      newFiltered = listings.filter(
        (listing) =>
          listing.data.region === filters.region &&
          listing.data.type === filters.status
      );
    } else if (filters.region && filters.search) {
      newFiltered = listings.filter(
        (listing) =>
          listing.data.region === filters.region &&
          listing.data.city.toLowerCase().includes(filters.search.toLowerCase())
      );
    } else if (filters.country && filters.status) {
      newFiltered = listings.filter(
        (listing) =>
          listing.data.country === filters.country &&
          listing.data.type === filters.status
      );
    } else if (filters.country && filters.search) {
      newFiltered = listings.filter(
        (listing) =>
          listing.data.country === filters.country &&
          listing.data.city.toLowerCase().includes(filters.search.toLowerCase())
      );
    } else if (filters.status && filters.search) {
      newFiltered = listings.filter(
        (listing) =>
          listing.data.type === filters.status &&
          listing.data.city.toLowerCase().includes(filters.search.toLowerCase())
      );
    } else if (filters.region) {
      newFiltered = listings.filter(
        (listing) => listing.data.region === filters.region
      );
    } else if (filters.country) {
      newFiltered = listings.filter(
        (listing) => listing.data.country === filters.country
      );
    } else if (filters.status) {
      newFiltered = listings.filter(
        (listing) => listing.data.type === filters.status
      );
    } else if (filters.search) {
      newFiltered = listings.filter((listing) =>
        listing.data.city.toLowerCase().includes(filters.search.toLowerCase())
      );
    } else {
      newFiltered = listings;
    }

    setFilteredListings(newFiltered);
  };

  // handle reset function
  const resetFilters = () => {
    setFilters({
      region: '',
      country: '',
      status: '',
      search: '',
    });
    setFilteredListings(listings);
  };

  return (
    <div className="max-w-8xl mx-auto px-8">
      {/* <h1 className="text-3xl text-center mt-6 font-bold mb-6">Properties</h1> */}
      {/* properteis filter starting */}
      <div class="flex flex-col container">
        <h2 class="text-xl font-bold mb-4 text-stone-600 ">
          Filter properties
        </h2>

        <div class="bg-white p-6 mb-10 rounded-xl shadow-lg ">
          <div class=" grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-6">
            <div class="flex flex-col">
              <label for="name" class="font-medium text-sm text-stone-600">
                Region
              </label>
              <select
                value={filters.region}
                onChange={(e) => handleFilterChange('region', e.target.value)}
                class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#133042] focus:ring focus:ring-[#133042] focus:ring-opacity-50"
              >
                <option value="">Select a region</option>
                <option value="East Africa">East Africa</option>
                <option value="Southern Africa">Southern Africa</option>
                <option value="Central Africa">Central Africa</option>
                <option value="West Africa">West Africa</option>
                <option value="North Africa">North Africa</option>
              </select>
            </div>

            <div class="flex flex-col">
              <label for="date" class="font-medium text-sm text-stone-600">
                Countries
              </label>
              <select
                value={filters.country}
                onChange={(e) => handleFilterChange('country', e.target.value)}
                class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              >
                <option value="">Select a Countries</option>
                {availableCountreis.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
            <div class="flex flex-col">
              <label for="name" class="font-medium text-sm text-stone-600">
                Status
              </label>
              <select
                value={filters.status}
                onChange={(e) => handleFilterChange('status', e.target.value)}
                class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#133042] focus:ring focus:ring-[#133042] focus:ring-opacity-50"
              >
                <option value="">Select a type</option>
                <option value="sale">Sale</option>
                <option value="rent">Rent</option>
              </select>
            </div>
            <div class="flex flex-col">
              <label for="date" class="font-medium text-sm text-stone-600">
                Search
              </label>
              <input
                type="text"
                id="search"
                placeholder="Write the address"
                value={filters.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#133042] focus:ring focus:ring-[#133042] focus:ring-opacity-50"
              />
            </div>
            <div class="grid md:flex grid-cols-2 justify-end space-x-4 w-full mt-6">
              <button
                onClick={resetFilters}
                class="px-4 py-2 rounded-lg text-white bg-[#133042] hover:bg-stone-500 font-bold shadow-lg shadow-stone-200 transition ease-in-out duration-200 translate-10"
              >
                Reset
              </button>

              <button
                type="submit"
                onClick={applyFilters}
                class="px-4 py-2 rounded-lg text-white bg-[#68b71c] hover:bg-green-700 font-bold shadow-lg shadow-[#133042] transition ease-in-out duration-200 translate-10"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* properteis filter ending */}

      {loading ? (
        <Spinner />
      ) : filteredListings && filteredListings.length > 0 ? (
        <>
          <main>
            <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
              {filteredListings.map((listing) => (
                <ListingItem
                  key={listing.id}
                  id={listing.id}
                  listing={listing.data}
                />
              ))}
            </ul>
          </main>
          {lastFetchedListing && (
            <div className="flex justify-center items-center">
              <button
                onClick={onFetchMoreListings}
                className="bg-[#133042] px-3 py-1.5 text-white border border-gray-300 mb-6 mt-6 hover:border-slate-600 rounded transition duration-150 ease-in-out"
              >
                Load more
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="container">
          <p className="text-bold text-base text-green-600">Sorry! </p>
          <p className="mt-4 text-base text-[#133042]">
            There are no available properties
          </p>
        </div>
      )}
    </div>
  );
}
