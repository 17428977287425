import Morocco from './../../assets/images/morocco.jpg';
import SouthAfrica from './../../assets/images/southafrica.jpg';

export default [
  {
    title: 'East Africa',
    // description: 'You can find properties and lands in east africa',
    urls: Morocco,
  },
  {
    title: 'Southern Africa',
    // description: 'You can find properties and lands in southern africa',
    urls: SouthAfrica,
  },
  {
    title: 'West Africa',
    // description: 'You can find properties and lands in west africa',
    urls: Morocco,
  },
  {
    title: 'North Africa',
    // description: 'You can find properties and lands in north africa',
    urls: SouthAfrica,
  },
  {
    title: 'Central Africa',
    // description: 'You can find properties and lands in central africa',
    urls: Morocco,
  },
];
