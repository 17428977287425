// CustomerDataContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const CustomerDataContext = createContext();

export const useCustomerData = () => {
  return useContext(CustomerDataContext);
};

export const CustomerDataProvider = ({ children }) => {
  const [customerData, setCustomerData] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customersCollection = collection(db, 'customers');
        const customersSnapshot = await getDocs(customersCollection);
        const data = customersSnapshot.docs.map((doc) => doc.data());
        setCustomerData(data);
      } catch (error) {
        console.error('Error fetching customer data:', error);
      }
    };

    fetchData();
  }, [db]);

  return (
    <CustomerDataContext.Provider value={customerData}>
      {children}
    </CustomerDataContext.Provider>
  );
};
