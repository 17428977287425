import React from 'react';

export default function HowToUpload() {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-[#68b71c] uppercase rounded-full bg-teal-accent-400">
            work with afri-find
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-2xl font-bold leading-none tracking-tight text-[#133042] sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-[#68b71c] lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="84d09fa9-a544-44bd-88b2-08fdf4cddd38"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#84d09fa9-a544-44bd-88b2-08fdf4cddd38)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">How can I upload my </span>
          </span>{' '}
          property or land?
        </h2>
        <p className="text-base text-gray-800 md:text-lg">
          Follow these simple 3 steps:
        </p>
      </div>
      <div className="grid gap-8 row-gap-5 md:row-gap-8 lg:grid-cols-3">
        <div className="p-5 duration-300 transform bg-white border-2 border-dashed rounded shadow-sm border-[#133042] hover:-translate-y-2">
          <div className="flex items-center mb-2">
            <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-[#133042]">
              1
            </p>
            <p className="text-lg font-bold leading-5">Register</p>
          </div>
          <p className="text-sm text-gray-900 text-justify">
            By clicking on the “Upload” button towards the top right of this
            page, you will be given the change to create an account with a
            username and your email address.
          </p>
        </div>
        <div className="p-5 duration-300 transform bg-white border-2 border-dashed rounded shadow-sm border-[#133042] hover:-translate-y-2">
          <div className="flex items-center mb-2">
            <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-[#133042]">
              2
            </p>
            <p className="text-lg font-bold leading-5">
              Upload your chosen home or land
            </p>
          </div>
          <p className="text-sm text-gray-900 text-justify">
            Once you’ve created you’re account and your’re logged in, click on
            “account” towards the top right again and now upload any of your
            chosen homes or land that you wish to advertise and click create
            towards the bottom.
          </p>
        </div>
        <div className="relative p-5 duration-300 transform bg-white border-2 rounded shadow-sm border-[#68b71c] hover:-translate-y-2">
          <div className="flex items-center mb-2">
            <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-[#68b71c]">
              3
            </p>
            <p className="text-lg font-bold leading-5">
              Sit back & watch your property or land get shown to a local as
              well as a global audience.
            </p>
          </div>
          <p className="text-sm text-gray-900 text-justify">
            From this point on, all we require from you is that you answer the
            calls and emails coming in from potential buyers and renters that
            have found you through Afri Find.
          </p>
          <p className="absolute top-0 right-0 flex items-center justify-center w-8 h-8 -mt-4 -mr-4 font-bold rounded-full bg-[#68b71c] sm:-mt-5 sm:-mr-5 sm:w-10 sm:h-10">
            <svg
              className="text-white w-7"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <polyline
                fill="none"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="6,12 10,16 18,8"
              />
            </svg>
          </p>
        </div>
      </div>
    </div>
  );
}
