import { getAuth, updateProfile } from 'firebase/auth';
// import { doc, updateDoc } from "firebase/firestore";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db } from '../firebase';
import { BsHouseUpFill } from 'react-icons/bs';
import { FcNightLandscape } from 'react-icons/fc';
import { useEffect } from 'react';
import ListingItem from '../components/ListingItem';
import LandItem from '../components/LandItem';

export default function Profile() {
  const navigate = useNavigate();
  const [changeDetail, setChangeDetail] = useState(false);
  const [lands, setLands] = useState(null);
  const [listings, setListings] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const [formData, setFormData] = useState({
    name: auth.currentUser.displayName,
    email: auth.currentUser.email,
  });
  const { name, email } = formData;
  function onLogout() {
    auth.signOut();
    navigate('/');
  }

  // function onChange input
  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  // function onSubmit input data update
  async function onSubmit() {
    try {
      if (auth.currentUser.displayName !== name) {
        // update display name in firebase auth
        await updateProfile(auth.currentUser, {
          displayName: name,
        });

        // update name in the firestore
        const docRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(docRef, {
          name,
        });
      }
      toast.success('Profile details updated');
    } catch (error) {
      toast.error('Could not update the profile details');
    }
  }

  useEffect(() => {
    async function fetchUserListings() {
      const listingRef = collection(db, 'listings');
      const q = query(
        listingRef,
        where('userRef', '==', auth.currentUser.uid),
        orderBy('timestamp', 'desc')
      );
      const querySnap = await getDocs(q);
      let listings = [];
      querySnap.forEach((doc) => {
        return listings.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setListings(listings);
      setLoading(false);
    }
    fetchUserListings();
  }, [auth.currentUser.uid]);

  // land fetching
  useEffect(() => {
    async function fetchUserLands() {
      const landRef = collection(db, 'lands');
      const q = query(
        landRef,
        where('userRef', '==', auth.currentUser.uid),
        orderBy('timestamp', 'desc')
      );
      const querySnap = await getDocs(q);
      let lands = [];
      querySnap.forEach((doc) => {
        return lands.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setLands(lands);
      setLoading(false);
    }
    fetchUserLands();
  }, [auth.currentUser.uid]);

  // delete function
  // async function onDelete(listingID) {
  //   if (window.confirm('Are you sure you want to delete?')) {
  //     await deleteDoc(doc(db, 'listings', listingID));
  //     const updatedListings = listings.filter(
  //       (listing) => listing.id !== listingID
  //     );
  //     setListings(updatedListings);
  //     toast.success('Successfully deleted the listing');
  //   }
  // }

  // edit function
  function onEdit(listingID) {
    navigate(`/edit-listing/${listingID}`);
  }

  return (
    <>
      <section className="max-w-6xl mx-auto flex justify-center items-center flex-col">
        <h1 className="text-2xl text-center mt-8 font-bold capitalize">
          Hello {name}, Welcome to your Afri Find account
        </h1>
        <div className="w-full md:w-2/3 mt-8 px-4">
          <form>
            {/* name input */}
            <input
              type="text"
              id="name"
              value={name}
              disabled={!changeDetail}
              onChange={onChange}
              className={`w-full mb-6 px-4 py-3 text-lg text-gray-800 bg-gray-100 border-2 border-gray-200 rounded transition duration-300 ${
                changeDetail && 'bg-white'
              }`}
            />
            {/* email input */}
            <input
              type="email"
              id="email"
              value={email}
              disabled
              onChange={onChange}
              className="w-full mb-6 px-4 py-3 text-lg text-gray-800 bg-gray-100 border-2 border-gray-200 rounded transition duration-300"
            />
            <div className="flex justify-between text-base mb-8">
              <p className="flex items-center">
                Click "Edit" if you want to update
                <span
                  onClick={() => {
                    changeDetail && onSubmit();
                    setChangeDetail((prevState) => !prevState);
                  }}
                  className="text-blue-500 hover:text-blue-700 transition duration-300 ml-2 cursor-pointer"
                >
                  {changeDetail ? 'Save' : 'Edit'}
                </span>
              </p>
              <p
                onClick={onLogout}
                className="bg-red-500 rounded-full text-white px-4 py-2 transition duration-300 cursor-pointer"
              >
                Sign Out
              </p>
            </div>
          </form>
          <div className="grid grid-cols-2 gap-6">
            <button
              type="submit"
              className="bg-[#133042] text-white uppercase px-6 py-4 text-base
          font-medium rounded shadow-md hover:bg-blue-900 transition duration-300"
            >
              <Link to="/create-listing" className="flex items-center">
                <BsHouseUpFill className="mr-2 text-2xl bg-white text-black rounded-full p-1 border-2" />
                Upload Your Property
              </Link>
            </button>
            <button
              type="submit"
              className="bg-[#68b71c] text-white uppercase px-6 py-4 text-base
          font-medium rounded shadow-md hover:bg-green-600 transition duration-300"
            >
              <Link to="/create-land" className="flex items-center">
                <FcNightLandscape className="mr-2 text-2xl bg-white rounded-full p-1 border-2" />
                Upload Your Land
              </Link>
            </button>
          </div>
        </div>
      </section>

      {/* data fetching user properties register  */}
      <div className="max-w-6xl px-3 mt-6 mx-auto">
        {!loading && listings.length > 0 && (
          <>
            <h2 className="text-2xl text-center font-semibold mb-6">
              My Listings
            </h2>
            <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl-grid-cols-5 mt-6 mb-6">
              {listings.map((listing) => (
                <ListingItem
                  key={listing.id}
                  id={listing.id}
                  listing={listing.data}
                  // trigger
                  // onDelete={() => onDelete(listing.id)}
                  onEdit={() => onEdit(listing.id)}
                />
              ))}
            </ul>
            <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl-grid-cols-5 mt-6 mb-6">
              {lands.map((land) => (
                <LandItem
                  key={land.id}
                  id={land.id}
                  land={land.data}
                  // trigger
                  // onDelete={() => onDelete(listing.id)}
                  onEdit={() => onEdit(land.id)}
                />
              ))}
            </ul>
          </>
        )}
      </div>
    </>
  );
}
