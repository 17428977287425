import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { db } from '../../firebase';
import Spinner from '../Spinner';
import LandItem from '../LandItem';
import Slider from 'react-slick';
import './style.css';
import { Link } from 'react-router-dom';

const FeatureLands = () => {
  const [lands, setLands] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastFetchedLand, setLastFetchLand] = useState(null);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    // initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    fetchLands();
    // fetchCountries();
  }, []);

  // fetching lands
  // useEffect(() => {
  async function fetchLands() {
    try {
      const landRef = collection(db, 'lands');
      const q = query(
        landRef,
        where('land', '==', true),
        orderBy('timestamp', 'desc'),
        limit(15)
      );
      const querySnap = await getDocs(q);
      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchLand(lastVisible);
      const lands = [];
      querySnap.forEach((doc) => {
        return lands.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setLands(lands);
      setLoading(false);
    } catch (error) {
      toast.error('Could not fetch land');
    }
  }

  return (
    <div className="max-w-8xl p-4 rounded w-auto justify-center m-auto">
      <h2 className="px-20 text-xl mt-6 font-bold uppercase text-gray-600">
        Recent land
      </h2>
      <Link to="/lands">
        <p
          className="px-20 text-sm text-blue-600 hover:text-blue-800 transition duration-150
              ease-in-out"
        >
          View more land
        </p>
      </Link>
      <div className=" ">
        {loading ? (
          <Spinner />
        ) : lands && lands.length > 0 ? (
          <div className="container">
            <Slider {...settings}>
              {lands.map((land) => (
                <LandItem key={land.id} id={land.id} land={land.data} />
              ))}
            </Slider>
          </div>
        ) : (
          <div className="container">
            <p className="text-bold text-base text-green-600">Sorry! </p>
            <p className="mt-4 text-base text-[#133042]">
              There are no available lands
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeatureLands;
