// // note firebase.js and firebase.config it's same

// // Import the functions you need from the SDKs you need
// import { initializeApp } from 'firebase/app';
// import { getFirestore } from 'firebase/firestore';
// import {getAnalytics} from 'firebase/analytics';
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: 'AIzaSyDP35bUHGrP6ViD0eKf8edCMj_JMQ4UPsU',
//   authDomain: 'afrifind-platform.firebaseapp.com',
//   projectId: 'afrifind-platform',
//   storageBucket: 'afrifind-platform.appspot.com',
//   messagingSenderId: '674709492407',
//   appId: '1:674709492407:web:f522ede5963377a47df332',
// };

// // Initialize Firebase
// initializeApp(firebaseConfig);
// export const db = getFirestore();

// new firebase config
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDP35bUHGrP6ViD0eKf8edCMj_JMQ4UPsU',
  authDomain: 'afrifind-platform.firebaseapp.com',
  projectId: 'afrifind-platform',
  storageBucket: 'afrifind-platform.appspot.com',
  messagingSenderId: '674709492407',
  appId: '1:674709492407:web:f522ede5963377a47df332',
  measurementId: 'G-8J5NPLDCKJ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore();
