import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router
import somaliland from '../assets/images/somaliland.jpg';
import blogimg1 from '../assets/images/blog1.png';

// import BlogDetailPage from '../components/BlogDetail';

const Blogs = () => {
  const blogsData = [
    {
      id: 1,
      title:
        'The lack of digitalisation hampering Africa’s $143 billion dollar Real Estate sector, is there a solution?',
      image: blogimg1,
      // description:
      //   'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure veritatis sint autem nesciunt, laudantium quia tempore delect.',
      // content: `
      //   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non dui est. Nullam auctor velit non erat tincidunt, nec tincidunt mi fermentum.</p>
      //   <p>Donec finibus euismod lectus, eu rhoncus tortor ultrices nec. Fusce ac sem eget felis consectetur malesuada. Integer ut urna sit amet odio volutpat malesuada.</p>
      //   <p>Nunc in nisi euismod, hendrerit sapien id, rhoncus elit. Sed consequat nulla vitae fringilla fringilla. Curabitur congue, ex a sollicitudin sagittis.</p>
      // `,
    },
    // {
    //   id: 2,
    //   title: 'Blog Two Title',
    //   image: somalia,
    //   description:
    //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure veritatis sint autem nesciunt, laudantium quia tempore delect.',
    //   content: `
    //     <p>Curabitur id massa purus. Maecenas quis sapien massa. Integer ut ipsum non dui vehicula dignissim.</p>
    //     <p>Aliquam tincidunt velit eu tellus facilisis congue. Sed euismod elit nec arcu feugiat, non pharetra justo ultrices.</p>
    //     <p>Ut vestibulum libero id venenatis aliquet. Pellentesque a orci id urna rhoncus malesuada nec sit amet erat.</p>
    //   `,
    // },
  ];

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="container px-6 py-10 mx-auto">
        <div className="text-center">
          <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">
            AfriFind Proptech blogs
          </h1>
          <p className="max-w-lg mx-auto mt-4 text-gray-500">
            Here you can see some blogs about the future properties and land in
            African regions.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-8 mt-8 lg:grid-cols-2">
          {blogsData.map((blog) => (
            <div key={blog.id}>
              <img
                className="relative z-10 object-contian w-full rounded-md h-96"
                src={blog.image}
                alt={blog.title}
              />
              <div className="relative z-20 max-w-lg p-6 mx-auto -mt-20 bg-white rounded-md shadow dark:bg-gray-900">
                <Link
                  to={`/blogs/${blog.id}`}
                  className="font-semibold text-gray-800 hover:underline dark:text-white md:text-xl"
                >
                  {blog.title}
                </Link>
                <p className="mt-3 text-sm text-gray-500 dark:text-gray-300 md:text-sm">
                  {blog.description}
                </p>
                <Link
                  to={`/blogs/${blog.id}`}
                  className="mt-3 text-sm text-blue-500 cursor-pointer"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
