import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from '@heroicons/react/20/solid';
import logo from '../assets/images/afrifindlogo1.jpg';
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { FiPhoneCall } from 'react-icons/fi';

// const products = [
//   {
//     name: 'Analytics',
//     description: 'Get a better understanding of your traffic',
//     href: '#',
//     icon: ChartPieIcon,
//   },
//   {
//     name: 'Engagement',
//     description: 'Speak directly to your customers',
//     href: '#',
//     icon: CursorArrowRaysIcon,
//   },
//   {
//     name: 'Security',
//     description: 'Your customers’ data will be safe and secure',
//     href: '#',
//     icon: FingerPrintIcon,
//   },
//   {
//     name: 'Integrations',
//     description: 'Connect with third-party tools',
//     href: '#',
//     icon: SquaresPlusIcon,
//   },
//   {
//     name: 'Automations',
//     description: 'Build strategic funnels that will convert',
//     href: '#',
//     icon: ArrowPathIcon,
//   },
// ];
// const callsToAction = [
//   { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
//   { name: 'Contact sales', href: '#', icon: PhoneIcon },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  // hook for dynamic sign in and profile
  const [pageState, setPageState] = useState('Sign in');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setPageState('Account');
      } else {
        setPageState('Upload');
      }
    });
  }, [auth]);
  const location = useLocation();
  //   this function we use if location path is true make character like border bottom green
  function pathMatchRoute(route) {
    if (route === location.pathname) {
      return true;
    }
  }

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
      <header className="bg-[#133042] shadow-sm sticky top-0 z-40">
        <div className="bg-[#68b71c] p-1 flex justify-around">
          <div className="flex space-x-3 ">
            <a href="mailto:info@afri-find.com?subject=Hello&body=I connected to the website">
              <p className="flex  text-white text-sm sm:text-sm justify-center items-center cursor-pointer">
                <MdEmail className="text-sm mr-2 sm:text-sm" />
                Info@afri-find.com
              </p>
            </a>
            {/* <p className="flex text-gray-200 text-sm  justify-center items-center">
              <FiPhoneCall className="text-sm lg:text-sm mr-2" />
              +252 637774718
            </p> */}
          </div>
          <div className="flex space-x-1">
            <a href="tel:+252637774718">
              <p className="flex text-white text-sm  justify-center items-center cursor-pointer">
                <FiPhoneCall className="text-sm lg:text-sm mr-2" />
                +252 637774718
              </p>
            </a>
            {/* <a
              href="#"
              className="p-1 text-white  items-center space-x-2 rounded-full"
            >
              <BsFacebook />
            </a>
            <a
              href="#"
              className=" p-1  text-white  items-center space-x-2 rounded-full"
            >
              <BsTwitter />
            </a>
            <a
              href="#"
              className=" p-1  text-white  items-center space-x-2 rounded-full"
            >
              <BsLinkedin />
            </a>
            <a
              href="#"
              className=" p-1  text-white  items-center space-x-2 rounded-full"
            >
              <BsInstagram />
            </a> */}
          </div>
        </div>
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-6"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">AfriFind</span>
              <img className="h-14 w-auto rounded-md" src={logo} alt="logo" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <Popover.Group className="hidden lg:flex lg:gap-x-12">
            {/* <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
              More
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <a
                          href={item.href}
                          className="block font-semibold text-gray-900"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover> */}

            <a
              href="/"
              className={`text-sm font-semibold leading-6 text-white border-b-[3px] border-b-transparent ${
                pathMatchRoute('/') && 'text-white font-bold border-b-green-500'
              }`}
            >
              Home
            </a>
            <a
              href="/properties"
              className={`text-sm font-semibold leading-6 text-white border-b-[3px] border-b-transparent ${
                pathMatchRoute('/properties') &&
                'text-white font-bold border-b-green-500'
              }`}
            >
              Properties
            </a>
            <a
              href="/lands"
              className={`text-sm font-semibold leading-6 text-white border-b-[3px] border-b-transparent ${
                pathMatchRoute('/lands') &&
                'text-white font-bold border-b-green-500'
              }`}
            >
              Land
            </a>
            <a
              href="/about"
              className={`text-sm font-semibold leading-6 text-white border-b-[3px] border-b-transparent ${
                pathMatchRoute('/about') &&
                'text-white font-bold border-b-green-500'
              }`}
            >
              About
            </a>
            <a
              href="/crms"
              className={`text-sm font-semibold leading-6 text-white border-b-[3px] border-b-transparent ${
                pathMatchRoute('/crm') &&
                'text-white font-bold border-b-green-500'
              }`}
            >
              CRM
            </a>
            {/* <a
              href="/customers"
              className={`text-sm font-semibold leading-6 text-white border-b-[3px] border-b-transparent ${
                pathMatchRoute('/customers') &&
                'text-white font-bold border-b-green-500'
              }`}
            >
              Customers
            </a> */}
            <a
              href="/blogs"
              className={`text-sm font-semibold leading-6 text-white border-b-[3px] border-b-transparent ${
                pathMatchRoute('/about') &&
                'text-white font-bold border-b-green-500'
              }`}
            >
              Blogs
            </a>
            <a
              href="/contact"
              className={`text-sm font-semibold leading-6 text-gray-100 border-b-[3px] border-b-transparent ${
                pathMatchRoute('/contact') &&
                'text-white font-bold border-b-green-500'
              }`}
            >
              Contact
            </a>
          </Popover.Group>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-3">
            {/* <a
              href="/sign-in"
              className="text-sm font-semibold leading-6 text-white bg-green-600 px-4 py-1 rounded-sm hover:bg-black "
            >
              Sign In
            </a> */}
            <ul>
              <li
                // href="/sign-in"
                className={`cursor-pointer py-1 px-4 rounded-full bg-white text-sm font-semibold leading-6 text-[#133042] border-b-[3px] border-b-transparent ${
                  (pathMatchRoute('/sign-in') || pathMatchRoute('/profile')) &&
                  'text-[#133042] font-bold border-b-green-500'
                }`}
                onClick={() => navigate('/profile')}
              >
                {pageState}
              </li>
            </ul>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center mt-10">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Afri-Find</span>
                {/* <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                /> */}
              </a>
              {/* <button
                type="button"
                className="-m-1 p-1 text-white font-bold mt-14 bg-red-800 rounded-full"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button> */}
            </div>
            <div className="mt-1 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-8">
                  <a
                    href="/"
                    className="-mx-3 block rounded-lg mt-2 px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Home
                  </a>
                  <a
                    href="/properties"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Properties
                  </a>
                  <a
                    href="/lands"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Land
                  </a>
                  <a
                    href="/about"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    About
                  </a>
                  <a
                    href="/crm"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    CRM
                  </a>
                  <a
                    href="/customers"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Customers
                  </a>
                  <a
                    href="/contact"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Contact
                  </a>
                  {/* <a
                    // href="/sign-in"
                    className={` -mx-3 blockcursor-pointer py-1 px-4 rounded-full bg-[#133042] text-sm font-semibold leading-6 text-white border-b-[3px] border-b-transparent ${
                      (pathMatchRoute('/sign-in') ||
                        pathMatchRoute('/profile')) &&
                      'text-black font-bold border-b-green-500'
                    }`}
                    onClick={() => {
                      navigate('/profile');
                      toggleMobileMenu();
                    }}
                  >
                    {pageState}
                  </a> */}
                </div>

                <div className="space-y-2 py-6">
                  {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-3"> */}
                  {/* <a
              href="/sign-in"
              className="text-sm font-semibold leading-6 text-white bg-green-600 px-4 py-1 rounded-sm hover:bg-black "
            >
              Sign In
            </a> */}
                  <ul>
                    <li
                      // href="/sign-in"
                      className={`cursor-pointer py-1 px-4 rounded-full bg-[#133042] text-sm font-semibold leading-6 text-white border-b-[3px] border-b-transparent ${
                        (pathMatchRoute('/sign-in') ||
                          pathMatchRoute('/profile')) &&
                        'text-black font-bold border-b-green-500'
                      }`}
                      onClick={() => {
                        navigate('/profile');
                        toggleMobileMenu();
                      }}
                    >
                      {pageState}
                    </li>
                  </ul>
                  {/* </div> */}
                </div>
                {/* <button
                  type="button"
                  className="-m-1 p-1 text-white font-bold mt-4 bg-red-700 rounded-full"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </>
  );
}
