import React, { useEffect, useState } from 'react';
// import Slider from '../components/Slider';
import {
  collection,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import propertyData from '../components/ListingItem';
import landData from '../components/LandItem';
// import Hero from '../components/Hero';
import Slider2 from '../components/Slider/Slider';
import Services from '../components/Services';
import HowToUpload from '../components/HowToUpload';
import Testimonials from '../components/Testimonials';
import FeatureProperties from '../components/FeaturesSliders/FeatureProperties';
import Slider from 'react-slick';
import FeatureLands from '../components/FeaturesSliders/FeatureLands';
import Hero from '../components/Hero';
import Regions from '../components/Regions/Regions';
import UpcomingService from '../components/UpcomingService';

export default function Home() {
  const allData = [];
  // propertys
  const [propertyListings, setPropertyListings] = useState(null);
  useEffect(() => {
    async function fetchListings() {
      try {
        // get reference
        const listingsRef = collection(db, 'listings');
        // create the query
        const q = query(
          listingsRef,
          where('property', '==', true),
          orderBy('timestamp', 'desc'),
          limit(5)
        );
        // execute the query
        const querySnap = await getDocs(q);
        const listings = [];
        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setPropertyListings(listings);
      } catch (error) {
        console.log(error);
      }
    }
    fetchListings();
  }, []);
  // lands
  const [landListings, setLandListings] = useState(null);
  useEffect(() => {
    async function fetchListings() {
      try {
        // get reference
        const landRef = collection(db, 'lands');
        // create the query
        const q = query(
          landRef,
          where('land', '==', true),
          orderBy('timestamp', 'desc'),
          limit()
        );
        // execute the query
        const querySnap = await getDocs(q);
        const lands = [];
        querySnap.forEach((doc) => {
          return lands.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setLandListings(lands);
      } catch (error) {
        console.log(error);
      }
    }
    fetchListings();
  }, []);
  // place for rent
  const [rentListings, setRentListings] = useState(null);
  useEffect(() => {
    async function fetchListings() {
      try {
        // get reference
        const listingsRef = collection(db, 'listings');
        // create the query
        const q = query(
          listingsRef,
          where('type', '==', 'rent'),
          orderBy('timestamp', 'desc'),
          limit(4)
        );
        // execute the query
        const querySnap = await getDocs(q);
        const listings = [];
        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setRentListings(listings);
      } catch (error) {
        console.log(error);
      }
    }
    fetchListings();
  }, []);
  // place for sale
  const [saleListings, setSaleListings] = useState(null);
  useEffect(() => {
    async function fetchListings() {
      try {
        // get reference
        const listingsRef = collection(db, 'listings');
        // create the query
        const q = query(
          listingsRef,
          where('type', '==', 'sale'),
          orderBy('timestamp', 'desc'),
          limit(4)
        );
        // execute the query
        const querySnap = await getDocs(q);
        const listings = [];
        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setSaleListings(listings);
      } catch (error) {
        console.log(error);
      }
    }
    fetchListings();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div>
      <Slider2 />
      {/* <Hero /> */}
      {/* <Slider /> */}
      <FeatureProperties />
      <FeatureLands />
      {/* <div className="max-w-6xl mx-auto pt-4 space-y-6">
        {propertyListings && propertyListings.length > 0 && (
          <div className="m-2 mb-6">
            <h2 className="px-3 text-2xl mt-6 font-semibold">
              Recent Properties
            </h2>
            <Link to="/properties">
              <p
                className="px-3 text-sm text-blue-600 hover:text-blue-800 transition duration-150
              ease-in-out"
              >
                Show more properties
              </p>
            </Link>

            <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
              {propertyListings.map((listing) => (
                <ListingItem
                  key={listing.id}
                  listing={listing.data}
                  id={listing.id}
                />
              ))}
            </ul>
          </div>
        )}

        {landListings && landListings.length > 0 && (
          <div className="m-2 mb-6">
            <h2 className="px-3 text-2xl mt-6 font-semibold">Recent Lands</h2>
            <Link to="/lands">
              <p
                className="px-3 text-sm text-blue-600 hover:text-blue-800 transition duration-150
              ease-in-out"
              >
                Show more lands
              </p>
            </Link>
            <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
              <Slider {...settings}>
                {landListings.map((land) => (
                  <LandItem key={land.id} land={land.data} id={land.id} />
                ))}
              </Slider>
            </ul>
          </div>
        )}
      </div> */}
      {/* regions */}
      <Regions allData={allData} />
      {/* services */}
      <Services />
      <UpcomingService />
      {/* how to upload */}
      <HowToUpload />
      <Testimonials />
    </div>
  );
}
