import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { db } from '../firebase';
import Spinner from '../components/Spinner';
import LandItem from '../components/LandItem';
import { async } from '@firebase/util';

export default function Lands() {
  const [lands, setLands] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastFetchedLand, setLastFetchLand] = useState(null);
  const [availableCountreis, setAvailableCountries] = useState([]);
  const [filters, setFilters] = useState({
    region: '',
    country: '',
    search: '',
  });
  const [filteredLands, setFilteredLands] = useState(null); // New state for filtered lands

  // handle filter change
  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
    applyFilters(value); // Call the applyFilters function with the updated value
  };

  // handle search function
  const applyFilters = (value) => {
    let newFiltered;

    // Use the state values for filtering
    if (filters.region && filters.country && filters.search) {
      newFiltered = lands.filter(
        (land) =>
          land.data.region === filters.region &&
          land.data.country === filters.country &&
          land.data.city.toLowerCase().includes(filters.search.toLowerCase())
      );
    } else if (filters.region && filters.country) {
      newFiltered = lands.filter(
        (land) =>
          land.data.region === filters.region &&
          land.data.country === filters.country
      );
    } else if (filters.region && filters.search) {
      newFiltered = lands.filter(
        (land) =>
          land.data.region === filters.region &&
          land.data.city.toLowerCase().includes(filters.search.toLowerCase())
      );
    } else if (filters.country && filters.search) {
      newFiltered = lands.filter(
        (land) =>
          land.data.country === filters.country &&
          land.data.city.toLowerCase().includes(filters.search.toLowerCase())
      );
    } else if (filters.region) {
      newFiltered = lands.filter((land) => land.data.region === filters.region);
    } else if (filters.country) {
      newFiltered = lands.filter(
        (land) => land.data.country === filters.country
      );
    } else if (filters.search) {
      newFiltered = lands.filter((land) =>
        land.data.city.toLowerCase().includes(filters.search.toLowerCase())
      );
    } else {
      newFiltered = lands;
    }

    setFilteredLands(newFiltered);
  };

  // Fetch lands
  async function fetchLands() {
    try {
      const landRef = collection(db, 'lands');
      const q = query(
        landRef,
        where('land', '==', true),
        orderBy('timestamp', 'desc')
      );
      const querySnap = await getDocs(q);
      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchLand(lastVisible);
      const lands = [];
      querySnap.forEach((doc) => {
        return lands.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setLands(lands);
      setLoading(false);
    } catch (error) {
      toast.error('Could not fetch land');
    }
  }

  // Fetch countries available
  async function fetchCountries() {
    try {
      const landRef = collection(db, 'lands');
      const q = query(landRef);
      const querySnap = await getDocs(q);
      const countries = new Set();
      querySnap.forEach((doc) => {
        const country = doc.data().country;
        if (country) {
          countries.add(country);
        }
      });
      setAvailableCountries(Array.from(countries));
    } catch (error) {
      toast.error('Could not fetch countries');
    }
  }

  // Load more function
  async function onFetchMoreLands() {
    try {
      const landRef = collection(db, 'lands');
      const q = query(
        landRef,
        where('land', '==', true),
        orderBy('timestamp', 'desc'),
        startAfter(lastFetchedLand)
      );
      const querySnap = await getDocs(q);
      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchLand(lastVisible);
      const lands = [];
      querySnap.forEach((doc) => {
        return lands.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setFilteredLands((prevState) => [...prevState, ...lands]);
      setLoading(false);
    } catch (error) {
      toast.error('Could not fetch land');
    }
  }

  // Fetch lands and countries
  useEffect(() => {
    fetchLands();
    fetchCountries();
  }, []);

  useEffect(() => {
    setFilteredLands(lands);
  }, [lands]);

  // handle reset function
  const resetFilters = () => {
    setFilters({
      region: '',
      country: '',
      search: '',
    });
    setFilteredLands(lands);
  };

  return (
    <div className="max-w-8xl mx-auto px-8">
      {/* ... (existing code) */}
      <div className="flex flex-col container">
        <h2 class="text-xl font-bold mb-2 mt-2 text-stone-600 ">Filter land</h2>
        <div class="bg-white p-6 mb-10 rounded-xl shadow-lg">
          <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-6">
            <div class="flex flex-col">
              <label for="name" class="font-medium text-sm text-stone-600">
                Region
              </label>
              <select
                value={filters.region}
                onChange={(e) => handleFilterChange('region', e.target.value)}
                class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#133042] focus:ring focus:ring-[#0e1e28] focus:ring-opacity-50"
              >
                <option value="">Select a region</option>
                <option value="East Africa">East Africa</option>
                <option value="Southern Africa">Southern Africa</option>
                <option value="Central Africa">Central Africa</option>
                <option value="West Africa">West Africa</option>
                <option value="North Africa">North Africa</option>
              </select>
            </div>

            <div class="flex flex-col">
              <label for="date" class="font-medium text-sm text-stone-600">
                Countries
              </label>
              <select
                value={filters.country}
                onChange={(e) => handleFilterChange('country', e.target.value)}
                class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#133042] focus:ring focus:ring-[#133042] focus:ring-opacity-50"
              >
                <option value="">Select a Countries</option>
                {availableCountreis.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
            <div class="flex flex-col">
              <label for="date" class="font-medium text-sm text-stone-600">
                Search
              </label>
              <input
                type="text"
                id="search"
                placeholder="Write the address"
                value={filters.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#133042] focus:ring focus:ring-[#133042] focus:ring-opacity-50"
              />
            </div>
            <div class="grid md:flex grid-cols-2 justify-end space-x-4 w-full mt-6">
              <button
                onClick={resetFilters}
                class="px-4 py-2 rounded-lg text-white bg-[#133042] hover:bg-stone-500 font-bold shadow-lg shadow-stone-200 transition ease-in-out duration-200 translate-10"
              >
                View All
              </button>
              <button
                type="submit"
                onClick={applyFilters}
                class="px-4 py-2 rounded-lg text-white bg-[#68b71c] hover:bg-green-700 font-bold shadow-lg shadow-green-200 transition ease-in-out duration-200 translate-10"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ... (existing code) */}
      {loading ? (
        <Spinner />
      ) : filteredLands && filteredLands.length > 0 ? (
        <>
          <main>
            <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
              {filteredLands.map((land) => (
                <LandItem key={land.id} id={land.id} land={land.data} />
              ))}
            </ul>
          </main>
          {lastFetchedLand && (
            <div className="flex justify-center items-center">
              <button
                onClick={onFetchMoreLands}
                className="bg-[#133042] px-3 py-1.5 text-white border border-gray-300 mb-6 mt-6 hover:border-slate-600 rounded transition duration-150 ease-in-out"
              >
                Load more
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="container">
          <p className="text-bold text-base text-green-600">Sorry! </p>
          <p className="mt-4 text-base text-[#133042]">
            There are no available land
          </p>
        </div>
      )}
    </div>
  );
}
