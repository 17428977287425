import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Properties from './pages/Properties';
import Lands from './pages/Lands';
import Profile from './pages/Profile';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import PrivateRoute from './components/PrivateRoute';
import ForgotPassword from './pages/ForgotPassword';
import Header from './components/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateListing from './pages/CreateListing';
import EditListing from './pages/EditListing';
import Property from './pages/Property';
import Land from './pages/Land';
import Category from './pages/Category';
import EditLand from './pages/EditLand';
import CreateLand from './pages/CreateLand';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import Error from './pages/404';
import Blogs from './pages/Blogs';
import BlogDetail from './components/BlogDetail';
import Crms from './pages/Crms';
// import Customers from './pages/Customers';
import { CustomerDataProvider } from './CustomerDataContext';

function App() {
  return (
    <>
      <Router>
        <CustomerDataProvider>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Error />} />
            <Route path="/about" element={<About />} />

            {/* <Route path="/customers" element={<Customers />} /> */}
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:id" element={<BlogDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/properties" element={<Properties />} />
            <Route path="/category/:categoryName" element={<Category />} />
            <Route
              path="/category/:categoryName/:listingId"
              element={<Property />}
            />
            <Route path="/land/:landId" element={<Land />} />
            {/* <Route path="/lands/:landId" element={<Lands />} /> */}
            <Route path="/lands" element={<Lands />} />
            <Route path="/profile" element={<PrivateRoute />}>
              <Route path="/profile" element={<Profile />} />
            </Route>
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="create-listing" element={<PrivateRoute />}>
              <Route path="/create-listing" element={<CreateListing />} />
            </Route>
            {/* create land private route */}
            <Route path="crms" element={<PrivateRoute />}>
              <Route path="/crms" element={<Crms />} />
            </Route>
            <Route path="create-land" element={<PrivateRoute />}>
              <Route path="/create-land" element={<CreateLand />} />
            </Route>
            <Route path="edit-listing" element={<PrivateRoute />}>
              <Route
                path="/edit-listing/:listingId"
                element={<EditListing />}
              />
            </Route>
            {/* private route land  */}
            <Route path="edit-land" element={<PrivateRoute />}>
              <Route path="/edit-land/:landId" element={<EditLand />} />
            </Route>
          </Routes>
          <Footer />
        </CustomerDataProvider>
      </Router>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;
