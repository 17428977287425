import React from 'react';
import logo from './../assets/images/afrifind-white.png';
import {
  BsFacebook,
  BsTwitter,
  BsInstagram,
  BsLinkedin,
  BsYoutube,
  BsTiktok,
} from 'react-icons/bs';

export default function Footer() {
  return (
    <section className="relative z-10 overflow-hidden bg-[#133042]">
      <div className="w-full px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
        <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="sm:col-span-2">
            <a
              href="/"
              aria-label="Go home"
              title="Company"
              className="inline-flex items-center"
            >
              <img
                src={logo}
                alt="logo"
                className="w-34 h-10 rounded-lg text-white"
              />
            </a>
            <div className="mt-6 lg:max-w-sm">
              <p className="text-sm text-white">
                We are AFRIFIND, a platform that is a one-stop shop for anyone
                that is either looking to buy, sell or rent property, as well as
                land
              </p>
            </div>
          </div>
          <div className="space-y-2 text-sm">
            <p className="text-base font-bold tracking-wide text-[#68b71c]">
              Contacts
            </p>
            <div className="flex">
              <p className="mr-1 text-[#68b71c]">Phone:</p>
              <a
                href="tel:+252-657163227"
                aria-label="Our phone"
                title="Our phone"
                className="transition-colors duration-300 text-white"
              >
                (+252) 65 7163227
              </a>
            </div>
            <div className="flex">
              <p className="mr-1 text-[#68b71c]">Email:</p>
              <a
                href="mailto:info@afri-find.com"
                aria-label="Our email"
                title="Our email"
                className="transition-colors duration-300 text-white"
              >
                info@afri-find.com
              </a>
            </div>
            <div className="flex">
              <p className="mr-1 text-[#68b71c]">Address:</p>
              <a
                href="https://www.google.com/maps"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our address"
                title="Our address"
                className="transition-colors duration-300 text-white"
              >
                Hargeisa, Somaliland <br />
                Omar Hashi Building, Floor 6
              </a>
            </div>
          </div>
          <div>
            <span className="text-base font-bold tracking-wide text-[#68b71c]">
              Follow Us
            </span>
            <div className="flex items-center mt-1 space-x-3">
              <a
                href="https://www.facebook.com/Afrifind"
                className="text-white transition-colors duration-300 hover:text-[#68b71c]"
              >
                <BsFacebook className="h-5 w-5 text-white" />
              </a>
              <a
                href="https://twitter.com/AfriFind1"
                className="text-white transition-colors duration-300 hover:text-[#68b71c]"
              >
                <BsTwitter className="h-5 w-5 text-white" />
              </a>
              <a
                href="https://www.linkedin.com/company/afri-find/"
                className="text-white transition-colors duration-300 hover:text-[#68b71c]"
              >
                <BsLinkedin className="h-5 w-5 text-white" />
              </a>
              <a
                href="https://www.instagram.com/afri.find/"
                className="text-white transition-colors duration-300 hover:text-[#68b71c]"
              >
                <BsInstagram className="h-5 w-5 text-white" />
              </a>
              <a
                href="https://www.tiktok.com/@afrifind"
                className="text-white transition-colors duration-300 hover:text-[#68b71c]"
              >
                <BsTiktok className="h-5 w-5 text-white" />
              </a>
              <a
                href="https://www.youtube.com/@afrifind"
                className="text-white transition-colors duration-300 hover:text-[#68b71c]"
              >
                <BsYoutube className="h-5 w-5 text-white" />
              </a>
            </div>
            {/* <p className="mt-4 text-sm text-gray-200">
              Starting your journey in digitalizing africa with afrifind, you
              need just follow in social media.
            </p> */}
          </div>
        </div>
        <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
          <p className="text-sm text-white">
            Copyright © {new Date().getFullYear()} Afri-Find. All rights
            reserved.
          </p>
          <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
            <li>
              <a
                href="/"
                className="text-sm text-gray-300 transition-colors duration-300 hover:text-white"
              >
                F.A.Q
              </a>
            </li>
            <li>
              <a
                href="/"
                className="text-sm text-gray-300 transition-colors duration-300 hover:text-white"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="/"
                className="text-sm text-gray-300 transition-colors duration-300 hover:text-white"
              >
                Terms &amp; Conditions
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
