import React from 'react';
import { FaUserTie } from 'react-icons/fa';

export default function Testimonials() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-8 mx-auto">
        <h1 className="max-w-lg mb-6 font-sans text-xl font-bold leading-none tracking-tight text-[#133042] sm:text-2xl md:mx-auto text-center">
          Testimonials
        </h1>
        <div className="flex flex-wrap -m-4">
          <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
            <div className="h-full text-center">
              <FaUserTie className="w-12 h-12 mb-8 object-cover object-center rounded-full inline-block border-1 border-gray-200 bg-white" />

              <p className="leading-relaxed">
                “As a Somali Diaspora I’m extremely grateful for Afri Find, as
                I’ve been searching high and low for a platform that gives me a
                pick of the best property and land available in my home
                country.“
              </p>
              <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
              <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
                SALEBAN JAMA
              </h2>
              <p className="text-gray-500">Sheffield, UK</p>
            </div>
          </div>
          <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
            <div className="h-full text-center">
              <FaUserTie className="w-12 h-12 mb-8 object-cover object-center rounded-full inline-block border-1 border-gray-200 bg-white" />
              <p className="leading-relaxed">
                “As a broker, I’ve been selling and renting properties in
                Kinsasha on behalf of property developers for a long time and I
                was reliant on word of mouth. Afri Find has connected me to my
                entire nation, the continent of Africa and the African
                Diaspora.“
              </p>
              <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
              <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
                GLOIRE JEAN
              </h2>
              <p className="text-gray-500">Kinshasa Congo</p>
            </div>
          </div>
          <div className="lg:w-1/3 lg:mb-0 p-4">
            <div className="h-full text-center">
              <FaUserTie className="w-12 h-12 mb-8 object-cover object-center rounded-full inline-block border-1 border-gray-200 bg-white" />
              <p className="leading-relaxed">
                “As a property developer, Afri Find has given me the ability to
                showcase all of our properties, directly to everyone in Nigeria
                as well as Africa and beyond.“
              </p>
              <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
              <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
                SANI AYOFEMI
              </h2>
              <p className="text-gray-500">Nigeria</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
