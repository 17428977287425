import React, { useState } from 'react';
import Spinner from '../components/Spinner';
import { toast } from 'react-toastify';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';

export default function CreateLand() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [geolocationEnabled, setGeolocationEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState(null);

  // form data structure
  const [formData, setFormData] = useState({
    // type: 'rent',
    name: '',
    size: 1,
    // bathrooms: 1,
    // parking: false,
    // furnished: false,
    address: '',
    region: '',
    country: '',
    city: '',
    discription: '',
    sellerName: '',
    sellerNumber: '',
    land: false,
    regularPrice: 0,
    discountedPrice: 0,
    latitude: 0,
    longitude: 0,
    images: {},
  });
  // destructuring form data

  const {
    // type,
    name,
    size,
    // bathrooms,
    // parking,
    // furnished,
    // address,
    region,
    country,
    city,
    discription,
    sellerName,
    sellerNumber,
    land,
    regularPrice,
    discountedPrice,
    latitude,
    longitude,
    images,
  } = formData;
  function onChange(e) {
    let boolean = null;
    if (e.target.value === 'true') {
      boolean = true;
    }
    if (e.target.value === 'false') {
      boolean = false;
    }
    // files
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        images: e.target.files,
      }));
    }
    // Text/Boolean/Number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  }

  function LocationMarker() {
    const customIcon = new Icon({
      iconUrl: require('../assets/images/africa.png'),
      iconSize: [33, 33],
    });
    const map = useMapEvents({
      click(e) {
        // map.locate();
        console.log('----', e.latlng);
        console.log(e.latlng.lat);
        console.log(e.latlng.lng);
        setPosition(e.latlng);
      },
      // locationfound(e) {
      //   setPosition(e.latlng);
      //   map.flyTo(e.latlng, map.getZoom());
      // },
    });

    return position === null ? null : (
      <Marker position={position} icon={customIcon}>
        <Popup>You are here</Popup>
      </Marker>
    );
  }

  // onSubmit form function
  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (+discountedPrice >= +regularPrice) {
      setLoading(false);
      toast.error('Discounted price needs to be less than regular price');
      return;
    }
    if (images.length > 6) {
      setLoading(false);
      toast.error('maximum images allowed are 6');
      return;
    }
    // location function on submit

    // let geolocation = {};
    // let location;
    // if (geolocationEnabled) {
    //   const response =
    //     await fetch(`https://maps.googleapis.com/maps/api/geocode/
    //   json?address=${address}&key=${process.env.REACT_APP_GEOCODE_API_KEY}`);
    //   const data = await response.json();
    //   console.log(data);
    //   geolocation.lat = data.results[0]?.geometry.location.lat ?? 0;
    //   geolocation.lng = data.results[0]?.geometry.location.lng ?? 0;

    //   location = data.status === 'ZERO_RESULTS' && undefined;

    //   if (location === undefined) {
    //     setLoading(false);
    //     toast.error('please enter a correct address');
    //     return;
    //   } else {
    //     geolocation.lat = latitude;
    //     geolocation.lng = longitude;
    //   }
    // }

    // store image function
    async function storeImage(image) {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const filename = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`;
        const storageRef = ref(storage, filename);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    }
    //   // images function
    const imgUrls = await Promise.all(
      [...images].map((image) => storeImage(image))
    ).catch((error) => {
      setLoading(false);
      toast.error('Images not uploaded');
      return;
    });
    // console.log(imgUrls);
    // }
    const formDataCopy = {
      ...formData,
      imgUrls,
      // position,
      latitude: position.lat,
      longitude: position.lng,
      timestamp: serverTimestamp(),
      userRef: auth.currentUser.uid,
    };
    delete formDataCopy.images;
    !formDataCopy.land && delete formDataCopy.discountedPrice;
    // delete formDataCopy.latitude;
    // delete formDataCopy.longitude;
    const docRef = await addDoc(collection(db, 'lands'), formDataCopy);
    setLoading(false);
    toast.success('Land created');
    navigate(`/land/${formDataCopy.id}/${docRef.id}`); //note the url is dynamiclly
    navigate(`/lands`);
  }

  if (loading) {
    return <Spinner />;
  }
  return (
    <main className="max-w-md px-2 mx-auto">
      <h1 className="text-3xl text-center mt-6 font-bold">Upload Land</h1>
      <form onSubmit={onSubmit}>
        {/* <p className="text-lg mt-6 font-semibold">Sell / Rent</p>
        <div className="flex">
          <button
            type="button"
            id="type"
            value="sale"
            onClick={onChange}
            className={`mr-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg
            transition duration-150 ease-in-out w-full ${
              type === 'rent'
                ? 'bg-white text-black'
                : 'bg-slate-600 text-white'
            }`}
          >
            sell
          </button>
          <button
            type="button"
            id="type"
            value="rent"
            onClick={onChange}
            className={`ml-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg
            transition duration-150 ease-in-out w-full ${
              type === 'sale'
                ? 'bg-white text-black'
                : 'bg-slate-600 text-white'
            }`}
          >
            rent
          </button>
        </div> */}
        <p className="text-lg mt-6 font-semibold">Name</p>
        <input
          // type="text"
          id="name"
          value="Land for sale"
          onChange={onChange}
          // placeholder="Name"
          // maxLength="32"
          // minLength="10"
          // required
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition 
          duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />
        <div className="flex space-x-6 mb-6">
          <div>
            <p className="text-lg font-semibold">Size</p>
            <input
              type="string"
              id="size"
              value={size}
              onChange={onChange}
              required
              className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition
              duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 text-center"
            />
          </div>
          {/* <div>
            <p className="text-lg font-semibold">Baths</p>
            <input
              type="number"
              id="bathrooms"
              value={bathrooms}
              onChange={onChange}
              min="1"
              max="50"
              required
              className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition
              duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 text-center"
            />
          </div> */}
        </div>
        {/* <p className="text-lg mt-6 font-semibold">Parking spot</p>
        <div className="flex">
          <button
            type="button"
            id="parking"
            value={true}
            onClick={onChange}
            className={`mr-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg
            transition duration-150 ease-in-out w-full ${
              !parking ? 'bg-white text-black' : 'bg-slate-600 text-white'
            }`}
          >
            yes
          </button>
          <button
            type="button"
            id="parking"
            value={false}
            onClick={onChange}
            className={`ml-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg
            transition duration-150 ease-in-out w-full ${
              parking ? 'bg-white text-black' : 'bg-slate-600 text-white'
            }`}
          >
            no
          </button>
        </div> */}
        {/* <p className="text-lg mt-6 font-semibold">Furnished</p>
        <div className="flex">
          <button
            type="button"
            id="furnished"
            value={true}
            onClick={onChange}
            className={`mr-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg
            transition duration-150 ease-in-out w-full ${
              !furnished ? 'bg-white text-black' : 'bg-slate-600 text-white'
            }`}
          >
            Yes
          </button>
          <button
            type="button"
            id="furnished"
            value={false}
            onClick={onChange}
            className={`ml-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg
            transition duration-150 ease-in-out w-full ${
              furnished ? 'bg-white text-black' : 'bg-slate-600 text-white'
            }`}
          >
            no
          </button>
        </div> */}
        <p className="text-lg mt-6 font-semibold">Region</p>
        <input
          type="text"
          id="region"
          value={region}
          onChange={onChange}
          placeholder="Region"
          maxLength="32"
          minLength="4"
          required
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition 
          duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />
        <p className="text-lg mt-6 font-semibold">Country</p>
        <input
          type="text"
          id="country"
          value={country}
          onChange={onChange}
          placeholder="Country"
          maxLength="32"
          minLength="4"
          required
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition 
          duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />
        <p className="text-lg mt-6 font-semibold">City</p>
        <input
          type="text"
          id="city"
          value={city}
          onChange={onChange}
          placeholder="City"
          maxLength="32"
          minLength="4"
          required
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition 
          duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />
        <div
          className="map"
          style={{ width: ' 350px', height: '350px' }}
          id="map"
          // onClick={addMarker}
        >
          <MapContainer
            center={{ lat: 9.1021, lng: 18.2812 }}
            zoom={3}
            scrollWheelZoom={false}
            className="z-10"
          >
            <TileLayer
              attribution="&copy;  Afri-find"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker />
          </MapContainer>
        </div>

        {/* <p className="text-lg mt-6 font-semibold">Address</p>
        <textarea
          type="text"
          id="address"
          value={address}
          onChange={onChange}
          placeholder="Address"
          required
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition 
          duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />
        {!geolocationEnabled && (
          <div className="flex space-x-6 justify-start mb-6">
            <div className="">
              <p className="text-lg font-semibold">Latitude</p>
              <input
                type="number"
                id="latitude"
                value={latitude}
                onChange={onChange}
                // min="-90"
                // max="90"
                className="w-full px-4 py-2 text-xl bg-white text-gray-7oo border border-gray-300
                rounded transition duration-150 ease-in-out focus:bg-white focus:text-gray-700 
                focus:border-slate-600 text-center"
              />
            </div>
            <div className="">
              <p className="text-lg font-semibold">Longitude</p>
              <input
                type="number"
                id="longitude"
                value={longitude}
                onChange={onChange}
                // min="-180"
                // max="180"
                className="w-full px-4 py-2 text-xl bg-white text-gray-7oo border border-gray-300
                rounded transition duration-150 ease-in-out focus:bg-white focus:text-gray-700 
                focus:border-slate-600 text-center"
              />
            </div>
          </div>
        )} */}
        <p className="text-lg font-semibold">Description</p>
        <textarea
          type="text"
          id="discription"
          value={discription}
          onChange={onChange}
          placeholder="Discription"
          required
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition 
          duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />
        <p className="text-lg mt-6 font-semibold">Contact person</p>
        <input
          type="text"
          id="sellerName"
          value={sellerName}
          onChange={onChange}
          placeholder="Contact person"
          maxLength="32"
          minLength="4"
          required
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition 
          duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />
        <p className="text-lg mt-6 font-semibold">Contact Number</p>
        <input
          type="number"
          id="sellerNumber"
          value={sellerNumber}
          onChange={onChange}
          placeholder="Contact person's number"
          maxLength="32"
          minLength="4"
          required
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition 
          duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />
        <p className="text-lg font-semibold">Land</p>
        <div className="flex mb-6">
          <button
            type="button"
            id="land"
            value={true}
            onClick={onChange}
            className={`mr-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg
            transition duration-150 ease-in-out w-full ${
              !land ? 'bg-white text-black' : 'bg-slate-600 text-white'
            }`}
          >
            Yes
          </button>
          <button
            type="button"
            id="land"
            value={false}
            onClick={onChange}
            className={`ml-3 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg
            transition duration-150 ease-in-out w-full ${
              land ? 'bg-white text-black' : 'bg-slate-600 text-white'
            }`}
          >
            no
          </button>
        </div>
        <div className="flex items-center mb-6">
          <div className="">
            <p className="text-lg font-semibold">Regular price</p>
            <div className="flex space-x-6 justify-center items-center w-full">
              <input
                type="number"
                id="regularPrice"
                value={regularPrice}
                onChange={onChange}
                min="25"
                max="1000000000"
                required
                className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 
                ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 text-center"
              />
              {/* {type === 'rent' && (
                <div className="">
                  <p className="text-md w-full whitespace-nowrap">$ / Month</p>
                </div>
              )} */}
            </div>
          </div>
        </div>
        {/* {land && (
          <div className="flex items-center mb-6">
            <div className="">
              <p className="text-lg font-semibold">Discounted price</p>
              <div className="flex space-x-6 justify-center items-center w-full">
                <input
                  type="number"
                  id="discountedPrice"
                  value={discountedPrice}
                  onChange={onChange}
                  min="0"
                  max="1000000000"
                  required={land}
                  className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 
                 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 text-center"
                />
              </div>
            </div>
          </div>
        )} */}
        <div className="mb-6">
          <p className="text-lg font-semibold">Images</p>
          <p className="text-gray-600">
            The first image will be the cover (max 6)
          </p>
          <input
            type="file"
            id="images"
            onChange={onChange}
            accept=".jpg,.png,.jpeg"
            multiple
            required
            className="w-full px-3 py-1.5 
             text-gray-700 bg-white border border-gray-300 rounded transition duration-150
             ease-in-out focus:bg-white focus:border-slate-600"
          />
        </div>
        <button
          type="submit"
          className="mb-6 w-full px-7 py-3 bg-blue-600 text-white font-medium text-sm uppercase
        rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg
        active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
        >
          Create Land
        </button>
      </form>
    </main>
  );
}
