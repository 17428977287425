import React from 'react';
import morocco from '../../assets/images/regionsimg/morocco.jpg';
import hargeisa from '../../assets/images/regionsimg/hargeisa2.jpg';
import rwanda from '../../assets/images/regionsimg/southafrica.jpg';
import somalia from '../../assets/images/regionsimg/one.jpg';
import jabuti from '../../assets/images/regionsimg/jabuuti.jpg';

const Regions = () => {
  return (
    <div className="section relative pt-20 pb-8 md:pt-16 md:pb-0 bg-white">
      <div className="container mb-4">
        <h1 className="text-2xl font-bold text-slate-600 mb-6 ">
          Find properties and land in these five regions
        </h1>
        <div className=" grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          <div className="max-w-sm rounded overflow-hidden shadow-lg relative text-center">
            <img src={morocco} alt="East Africa" className="w-full" />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 py-2 px-4 text-white">
              <div className="font-bold text-xl">East Africa</div>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden shadow-lg relative text-center">
            <img src={hargeisa} alt="West Africa" className="w-full" />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 py-2 px-4 text-white">
              <div className="font-bold text-xl">West Africa</div>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden shadow-lg relative text-center">
            <img src={rwanda} alt="Central Africa" className="w-full" />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 py-2 px-4 text-white">
              <div className="font-bold text-xl">Central Africa</div>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden shadow-lg relative text-center">
            <img src={somalia} alt="South Africa" className="w-full" />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 py-2 px-4 text-white">
              <div className="font-bold text-xl">Southern Africa</div>
            </div>
          </div>
          <div className="max-w-sm rounded overflow-hidden shadow-lg relative text-center">
            <img src={jabuti} alt="North Africa" className="w-full" />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 py-2 px-4 text-white">
              <div className="font-bold text-xl">North Africa</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Regions;
