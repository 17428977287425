import React from 'react';

export default function HeroAbout() {
  return (
    <section>
      <div className="2xl:container 2xl:mx-auto lg:py-10 lg:px-20 md:py-12 md:px-6 py-9 px-4">
        <div className="flex flex-col lg:flex-row justify-between gap-8">
          <div className="w-full lg:w-5/12 flex flex-col justify-center">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider bg-[#68b71c] uppercase rounded-full text-white">
                about us
              </p>
            </div>
            <h2 className="uppercase max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-[#133042] sm:text-4xl sm:leading-none">
              Who We {''}
              <span className="inline-block text-[#68b71c]">Are</span>
            </h2>
            <p className="text-base text-black md:text-lg text-justify">
              We are AFRI FIND, a platform that is a one-stop shop for anyone
              that is either looking to buy, sell or rent property, as well as
              land. We are not brokers or real estate agents, but rather a free
              platform that is connecting the entire Africa with other fellow
              Africans, the African diaspora and the rest of the world.
            </p>
          </div>

          <div className="w-full lg:w-8/12 ">
            <iframe
              className='w-full h-[484px] max-w-full border border-gray-200 rounded-lg dark:border-gray-700"'
              width="1271"
              height="4271"
              src="https://www.youtube.com/embed/5DKnnLN3VGI"
              title="Afri Find - Digitalising African Real Estate"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}
