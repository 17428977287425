import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  getFirestore,
  collection,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore';
import {
  Button,
  Card,
  CardHeader,
  Input,
  Tab,
  Tabs,
  TabsHeader,
  Typography,
} from '@material-tailwind/react';
import { MagnifyingGlassIcon, UserPlusIcon } from '@heroicons/react/24/solid';
import { debounce } from 'lodash';

const CustomerDataContext = createContext();

export const useCustomerData = () => {
  return useContext(CustomerDataContext);
};

const Customers = () => {
  const [customerData, setCustomerData] = useState([]);
  const [filteredCustomerData, setFilteredCustomerData] = useState([]);
  const [filters, setFilters] = useState({
    region: '',
    country: '',
    city: '',
    search: '',
    type: '',
  });

  const db = getFirestore();

  const fetchCountries = async () => {
    try {
      const response = await fetch('https://restcountries.com/v3.1/all');
      const data = await response.json();

      // Extract country names from the API response
      const countryNames = data.map((country) => country.name.common);

      return countryNames;
    } catch (error) {
      console.error('Error fetching countries:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customersCollection = collection(db, 'customers');
        const customersSnapshot = await getDocs(
          query(customersCollection, orderBy('timestamp', 'desc'))
        );
        const data = customersSnapshot.docs.map((doc) => {
          const customer = doc.data();
          const registrationTimestamp = customer.registrationDate;
          const registrationDate = registrationTimestamp
            ? new Date(registrationTimestamp.toDate())
            : null;
          return {
            ...customer,
            registrationDate,
          };
        });
        setCustomerData(data);
        setFilteredCustomerData(
          data.filter((customer) => customer.type === filters.type)
        );
      } catch (error) {
        console.error('Error fetching customer data:', error);
      }
    };

    fetchData();
  }, [db, filters.type]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const applyFilters = debounce(() => {
    let newFiltered = customerData;

    if (filters.address) {
      newFiltered = newFiltered.filter(
        (customer) =>
          customer?.address &&
          customer.address.toLowerCase().includes(filters.address.toLowerCase())
      );
    }

    if (filters.country) {
      newFiltered = newFiltered.filter(
        (customer) =>
          customer?.country &&
          customer.country.toLowerCase().includes(filters.country.toLowerCase())
      );
    }

    if (filters.city) {
      newFiltered = newFiltered.filter(
        (customer) =>
          customer?.city &&
          customer.city.toLowerCase().includes(filters.city.toLowerCase())
      );
    }

    if (filters.type) {
      newFiltered = newFiltered.filter(
        (customer) => customer?.type === filters.type
      );
    }

    setFilteredCustomerData(newFiltered);
  }, 300);

  const resetFilters = () => {
    setFilters({
      country: '',
      city: '',
      address: '',
      type: '',
    });
  };

  useEffect(() => {
    applyFilters();
  }, [filters, applyFilters]);

  // Tabs custom function
  const TABS = [
    { label: 'All', value: 'all' },
    {
      label: 'Property',
      value: 'property',
    },
    {
      label: 'Land',
      value: 'land',
    },
  ];

  // ...

  return (
    <Card className="h-full w-full container py-0">
      <CardHeader
        floated={false}
        shadow={false}
        className="rounded-none mb-2 p-2"
      >
        <div className="mb-4 flex items-center justify-between gap-4">
          <div>
            <Typography
              variant="h5"
              className="font-sans text-2xl font-semibold leading-tight tracking-normal text-red-700 "
            >
              Customer Data List
            </Typography>
            <p className="text-gray-800 mt-1 text-sm">
              Here you can find customers that wants homes and land.
              <span className="text-red-600">
                {' '}
                Please Keep the customer data.
              </span>
            </p>
          </div>
        </div>

        {/* filter section start */}
        <div class="bg-white p-6 mb-2 rounded-xl shadow-lg">
          <div class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-2">
            <div className="flex flex-col">
              <label
                htmlFor="type"
                className="font-medium text-sm text-stone-600"
              >
                Type
              </label>
              <select
                value={filters.type}
                onChange={(e) => handleFilterChange('type', e.target.value)}
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#133042] focus:ring focus:ring-[#0e1e28] focus:ring-opacity-50"
              >
                <option value="">All</option>
                <option value="Land">Land</option>
                <option value="Property_Sale">Property For Sale</option>
                <option value="Property_Rent">Property For Rent</option>
              </select>
            </div>

            <div class="flex flex-col">
              <label for="date" class="font-medium text-sm text-stone-600">
                Countries
              </label>
              <input
                type="text"
                id="country"
                placeholder="Enter Country"
                value={filters.country}
                onChange={(e) => handleFilterChange('country', e.target.value)}
                class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#133042] focus:ring focus:ring-[#133042] focus:ring-opacity-50"
              ></input>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="city"
                className="font-medium text-sm text-stone-600"
              >
                City
              </label>
              <input
                type="text"
                id="city"
                placeholder="Enter City"
                value={filters.city}
                onChange={(e) => handleFilterChange('city', e.target.value)}
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#133042] focus:ring focus:ring-[#0e1e28] focus:ring-opacity-50"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="search"
                className="font-medium text-sm text-stone-600"
              >
                Address
              </label>
              <input
                type="text"
                id="search"
                placeholder="Enter Address"
                value={filters.address}
                onChange={(e) => handleFilterChange('address', e.target.value)}
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#133042] focus:ring focus:ring-[#0e1e28] focus:ring-opacity-50"
              />
            </div>
            {/* Apply and Reset buttons */}
            <div className="grid md:flex grid-cols-2 justify-end space-x-4 w-full mt-7">
              <button
                onClick={resetFilters}
                className="px-4 py-2 rounded-lg text-white bg-[#133042] hover:bg-stone-500 font-bold shadow-lg shadow-stone-200 transition ease-in-out duration-200 translate-10"
              >
                Reset
              </button>
              <button
                type="submit"
                onClick={applyFilters}
                className="px-4 py-2 rounded-lg text-white bg-[#68b71c] hover:bg-green-700 font-bold shadow-lg shadow-green-200 transition ease-in-out duration-200 translate-10"
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
        {/* filter section end */}
      </CardHeader>
      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right tex-black">
          <thead className="text-xs uppercase bg-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Phone
              </th>
              <th scope="col" className="px-6 py-3">
                Type
              </th>
              <th scope="col" className="px-6 py-3">
                Country
              </th>
              <th scope="col" className="px-6 py-3">
                City
              </th>
              <th scope="col" className="px-6 py-3">
                Address
              </th>
              {/* <th scope="col" className="px-6 py-3">
          Registration Date
        </th> */}
            </tr>
          </thead>
          <tbody>
            {filteredCustomerData.map((customer) => (
              <tr key={customer.id} className="bg-white border-b ">
                <td className="px-6 py-4 font-medium  whitespace-nowrap ">
                  {customer.name}
                </td>
                <td className="px-6 py-4">{customer.phone}</td>
                <td className="px-6 py-4">{customer.type}</td>
                <td className="px-6 py-4">{customer.country}</td>
                <td className="px-6 py-4">{customer.city}</td>
                <td className="px-6 py-4">{customer.address}</td>
                {/* <td className="px-6 py-4">
            {customer.registrationDate
              ? customer.registrationDate.toDateString()
              : 'N/A'}
          </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default Customers;
