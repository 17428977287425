import React from 'react';

const UpcomingService = () => {
  return (
    <section class="text-gray-600 body-font">
      <div class="container px-5 py-24 mx-auto">
        <div class="text-center mb-20">
          <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Additional services coming soon!
          </h1>
          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-[#68b71c] inline-flex"></div>
          </div>
        </div>
        <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div class="p-4 md:w-1/2 flex flex-col text-center items-center">
            <div class="w-14 h-14 inline-flex items-center justify-center rounded-full bg-[#133042] text-white mb-5 flex-shrink-0">
              <span className="font-bold text-xl">1</span>
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                Afri Find Job Board
              </h2>
              <p class="leading-relaxed text-base text-gray-700">
                An opportunity for the African Real Estate sector to advertise
                their latest jobs on this industry specific platform for free
                and for job seekers to find their desired jobs.
              </p>
            </div>
          </div>
          <div class="p-4 md:w-1/2 flex flex-col text-center items-center">
            <div class="w-14 h-14 inline-flex items-center justify-center rounded-full bg-[#133042] text-white mb-5 flex-shrink-0">
              <span className="font-bold text-xl">2</span>
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                Afri Find CRM tool
              </h2>
              <p class="leading-relaxed text-base text-gray-700">
                Instead of recording all your leads, contracts and customers on
                paper that you could potentially lose, or time consuming excel
                records, the Afri Find CRM tool will allow you to have an
                integrated and smooth process, allowing you to streamline the
                way you work so that you can become time and money efficient.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingService;
